define("v-stars/templates/components/v-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BlJX8sho",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"ec-star\"],[15,\"onmouseover\",[30,[36,0],[[32,0],\"hover\",25],null]],[12],[13],[3,\"\\n\"],[10,\"span\"],[14,0,\"ec-star\"],[15,\"onmouseover\",[30,[36,0],[[32,0],\"hover\",50],null]],[12],[13],[3,\"\\n\"],[10,\"span\"],[14,0,\"ec-star\"],[15,\"onmouseover\",[30,[36,0],[[32,0],\"hover\",75],null]],[12],[13],[3,\"\\n\"],[10,\"span\"],[14,0,\"ec-star\"],[15,\"onmouseover\",[30,[36,0],[[32,0],\"hover\",100],null]],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"ec-stars-full \",[34,1]]]],[15,5,[34,2]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"color\",\"width\"]}",
    "meta": {
      "moduleName": "v-stars/templates/components/v-stars.hbs"
    }
  });

  _exports.default = _default;
});