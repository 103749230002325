define("x-page/components/x-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.isVisible}}
    <div class="form-group checkbox {{if @model.error "has-error"}}">
      <Checkbox
        @isChecked={{@model.value}}
        @isDisabled={{@readOnly}}
        @onClick={{action (mut @model.value)}}
      >
        {{!-- Allow triple-curlies for hardcoded html --}}
        {{!-- template-lint-disable triple-curlies --}}
        {{{@model.label}}}
      </Checkbox>
  
      <FieldError @model={{@model.error}} />
    </div>
  {{/if}}
  
  */
  {
    "id": "4mL5at/t",
    "block": "{\"symbols\":[\"@model\",\"@readOnly\"],\"statements\":[[6,[37,0],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"form-group checkbox \",[30,[36,0],[[32,1,[\"error\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[8,\"checkbox\",[],[[\"@isChecked\",\"@isDisabled\",\"@onClick\"],[[32,1,[\"value\"]],[32,2],[30,[36,2],[[32,0],[30,[36,1],[[32,1,[\"value\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"      \"],[2,[32,1,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"field-error\",[],[[\"@model\"],[[32,1,[\"error\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "x-page/components/x-check.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});