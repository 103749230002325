define("x-pdf/templates/components/x-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pVVNwqhl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[[30,[36,1],[[32,0,[\"isLoading\"]],[32,0,[\"isError\"]]],null],\"hide\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pdf-buttons\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,6,[31,[[32,0,[\"url\"]]]]],[14,0,\"fa-stack pdf-stack-icon\"],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[14,\"title\",\"Open in new tab to print or download\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-circle fa-stack-2x\"],[12],[13],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-external-link fa-stack-1x fa-inverse\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[2,\"  \"],[10,\"span\"],[14,0,\"pdf-body\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"v-loading\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isError\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-danger\"],[12],[2,\"\\n    We were unable to load the PDF at this time.\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"or\"]}",
    "meta": {
      "moduleName": "x-pdf/templates/components/x-pdf.hbs"
    }
  });

  _exports.default = _default;
});