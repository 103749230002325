define("v-avatar-cropper/templates/components/v-avatar-cropper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hEvlodNE",
    "block": "{\"symbols\":[],\"statements\":[[8,\"x-modal\",[],[[\"@title\",\"@aLabel\",\"@aClass\",\"@aAction\",\"@bLabel\",\"@bClass\",\"@bAction\"],[\"Edit Avatar\",\"Save\",\"btn-primary\",[30,[36,0],[[32,0],\"save\"],null],\"Cancel\",\"btn-default\",[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"cropper-container center-block\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"alt\",\"Image\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cropper-preview\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"close\"]}",
    "meta": {
      "moduleName": "v-avatar-cropper/templates/components/v-avatar-cropper.hbs"
    }
  });

  _exports.default = _default;
});