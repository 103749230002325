define("x-file-upload/components/x-file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['accept', 'capture', 'multiple', 'type'],
    type: 'file',
    maxMB: 25,
    ext: null,
    capture: null,
    accept: null,
    multiple: true,
    files: Ember.A(),

    change(event) {
      let newFiles = [...event.target.files].map(file => Ember.Object.create({
        name: file.name,
        size: file.size,
        data: file
      })).filter(file => this.files.every(x => x.name !== file.name));

      if (this.ext) {
        let badExt = Ember.A(newFiles.filter(file => !this.ext.some(ex => file.name.toLowerCase().endsWith(".".concat(ex)))));

        if (badExt.length > 0) {
          badExt.forEach(f => this.notify.error("".concat(f.name, " cannot be uploaded. Allowed file types are: ").concat(this.ext.join(', '), "."), {
            closeAfter: 12000
          }));
          newFiles = newFiles.filter(f => !badExt.includes(f));
        }
      }

      let newTotalSize = 0;
      newFiles.forEach(f => newTotalSize += f.size);
      this.files.forEach(f => newTotalSize += f.size);

      if (newTotalSize > this.maxMB * 1048576) {
        let msg = "Total file size must be less than ".concat(this.maxMB, "MB");

        if (this.notify) {
          this.notify.error(msg);
        } else {
          alert(msg);
        }

        this.clearInput();
        return;
      }

      if (newFiles.length > 0) {
        if (this.multiple) {
          this.files.addObjects(newFiles);
        } else {
          this.attrs.selected(newFiles[0]);
        }
      }

      this.clearInput();
    },

    clearInput() {
      // Clear hidden input
      let input = this.element;

      if (input) {
        input.value = null;
      }
    }

  });

  _exports.default = _default;
});