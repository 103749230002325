define("x-filters/components/x-filters", ["exports", "x-filters/templates/components/x-filters"], function (_exports, _xFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filters', 'clearfix'],
    classNameBindings: ['readonly'],
    layout: _xFilters.default,
    filterQuery: '',
    actions: {
      ignore() {// Used for read-only overlay
      },

      filterToggle(filter, nowChecked) {
        if (!nowChecked || filter.searchImmediately) {
          this.send('updateFilters');
        }
      },

      updateFilters() {
        let newFiltersParam = this.filterObjects.filter(x => x.active).filter(x => x.searchImmediately || Ember.isPresent(x.value)).map(x => x.serialize());
        this.filterObjects.setEach('dirty', false);

        if (newFiltersParam !== this.filterQuery) {
          this.updateFilters(JSON.stringify(newFiltersParam));
        }
      }

    }
  });

  _exports.default = _default;
});