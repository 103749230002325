define("x-page/components/x-date", ["exports", "@glimmer/component", "date-fns"], function (_exports, _component, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.isVisible}}
    <div class="form-group date {{if @model.error "has-error"}}">
      <label>
        {{!-- Allow triple-curlies for html content from api --}}
        {{!-- template-lint-disable triple-curlies --}}
        {{{@model.label}}}
      </label>
  
      {{#if @readOnly}}
        <div>
          {{moment-format @model.value @model.date.format}}
        </div>
      {{else}}
        <BsDatetimepicker
          @date={{@model.value}}
          @placeholder={{@model.date.format}}
          @openOnFocus={{true}}
          @format={{@model.date.format}}
          @minDate={{this.minDate}}
          @maxDate={{this.maxDate}}
        />
      {{/if}}
  
      <FieldError @model={{@model.error}} />
    </div>
  {{/if}}
  
  */
  {
    "id": "u4EEQKnQ",
    "block": "{\"symbols\":[\"@model\",\"@readOnly\"],\"statements\":[[6,[37,1],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"form-group date \",[30,[36,1],[[32,1,[\"error\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n\"],[2,\"      \"],[2,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"value\"]],[32,1,[\"date\",\"format\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"bs-datetimepicker\",[],[[\"@date\",\"@placeholder\",\"@openOnFocus\",\"@format\",\"@minDate\",\"@maxDate\"],[[32,1,[\"value\"]],[32,1,[\"date\",\"format\"]],true,[32,1,[\"date\",\"format\"]],[32,0,[\"minDate\"]],[32,0,[\"maxDate\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"field-error\",[],[[\"@model\"],[[32,1,[\"error\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "x-page/components/x-date.hbs"
    }
  });

  class PageDateComponent extends _component.default {
    constructor() {
      super(...arguments);
      let {
        value
      } = this.args.model;

      if (Ember.isEmpty(value)) {
        // `undefined` is not allowed
        this.args.model.value = null;
      } else if (typeof value === 'string') {
        // Convert string back to date (DB mismatch)
        this.args.model.value = new Date(value);
      }
    }

    get maxDate() {
      let {
        format,
        maxYear
      } = this.args.model.date;

      if (Ember.isEmpty(maxYear)) {
        return false;
      }

      let period = format === 'MM/YYYY' ? (0, _dateFns.endOfMonth)(new Date()) : (0, _dateFns.endOfDay)(new Date());
      return (0, _dateFns.addYears)(period, maxYear);
    }

    get minDate() {
      let {
        format,
        minYear
      } = this.args.model.date;

      if (Ember.isEmpty(minYear)) {
        return false;
      }

      let period = format === 'MM/YYYY' ? (0, _dateFns.startOfMonth)(new Date()) : (0, _dateFns.startOfDay)(new Date());
      return (0, _dateFns.addYears)(period, -minYear);
    }

  }

  _exports.default = PageDateComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PageDateComponent);
});