define("v-avatar/components/avatar-svg", ["exports", "v-avatar/utils/avatar-color", "v-avatar/templates/components/avatar-svg"], function (_exports, _avatarColor, _avatarSvg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _avatarSvg.default,
    tagName: '',
    initials: Ember.computed('name', function () {
      return this.get('name').replace(/(\w).* (\w).*/, "$1$2").substring(0, 2).toUpperCase();
    }),
    style: Ember.computed('name', function () {
      // Bootstrap's @print media query removes backgrounds using `!important`
      return Ember.String.htmlSafe("background-color: ".concat((0, _avatarColor.generateColor)(this.get('name')), " !important;"));
    })
  });

  _exports.default = _default;
});