define("x-video/templates/components/x-video-stream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xl/vAhti",
    "block": "{\"symbols\":[\"@largeName\",\"@name\",\"@placeholderUrl\"],\"statements\":[[10,\"div\"],[14,0,\"video-stream-container\"],[12],[2,\"\\n  \"],[10,\"video\"],[15,1,[32,0,[\"videoId\"]]],[15,0,[30,[36,1],[[32,1],\"object-scale-down\"],null]],[15,\"poster\",[32,3]],[15,\"playsInline\",true],[14,\"crossOrigin\",\"anonymous\"],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"video-stream-name-plate \",[30,[36,0],[[32,1],\"video-stream-name-plate-large\"],null]]]],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "x-video/templates/components/x-video-stream.hbs"
    }
  });

  _exports.default = _default;
});