define("@fortawesome/ember-fontawesome/components/fa-icon", ["exports", "@glimmer/component", "@fortawesome/fontawesome-svg-core", "ember-get-config"], function (_exports, _component, _fontawesomeSvgCore, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{~#if this.iconExists~}}
      <svg
          style={{this.safeStyle}}
          class={{this.iconAttributes.class}}
          data-prefix={{this.dataPrefix}}
          data-icon={{this.dataIcon}}
          data-fa-transform={{this.dataFaTransform}}
          data-fa-mask={{this.dataFaMask}}
          data-fa-processed={{this.dataFaProcessed}}
          aria-hidden={{this.ariaHidden}}
          aria-labelledby={{this.ariaLabelledBy}}
          focusable={{this.iconAttributes.focusable}}
          role={{this.iconAttributes.role}}
          xmlns={{this.iconAttributes.xmlns}}
          viewBox={{this.viewBox}}
          ...attributes
      >
          {{this.content}}
      </svg>
  {{~/if~}}
  
  */
  {
    "id": "OQ8tcMSb",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,0],[[32,0,[\"iconExists\"]]],null,[[\"default\"],[{\"statements\":[[11,\"svg\"],[16,5,[32,0,[\"safeStyle\"]]],[16,0,[32,0,[\"iconAttributes\",\"class\"]]],[16,\"data-prefix\",[32,0,[\"dataPrefix\"]]],[16,\"data-icon\",[32,0,[\"dataIcon\"]]],[16,\"data-fa-transform\",[32,0,[\"dataFaTransform\"]]],[16,\"data-fa-mask\",[32,0,[\"dataFaMask\"]]],[16,\"data-fa-processed\",[32,0,[\"dataFaProcessed\"]]],[16,\"aria-hidden\",[32,0,[\"ariaHidden\"]]],[16,\"aria-labelledby\",[32,0,[\"ariaLabelledBy\"]]],[16,\"focusable\",[32,0,[\"iconAttributes\",\"focusable\"]]],[16,\"role\",[32,0,[\"iconAttributes\",\"role\"]]],[16,\"xmlns\",[32,0,[\"iconAttributes\",\"xmlns\"]],\"http://www.w3.org/2000/xmlns/\"],[16,\"viewBox\",[32,0,[\"viewBox\"]]],[17,1],[12],[2,\"\\n        \"],[1,[32,0,[\"content\"]]],[2,\"\\n    \"],[13]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@fortawesome/ember-fontawesome/components/fa-icon.hbs"
    }
  });

  function objectWithKey(key, value) {
    return Array.isArray(value) && value.length > 0 || !Array.isArray(value) && value ? {
      [key]: value
    } : {};
  }

  function normalizeIconArgs(prefix, icon) {
    var _appConfig$fontawesom, _appConfig$fontawesom2;

    const defaultPrefix = (_appConfig$fontawesom = _emberGetConfig.default === null || _emberGetConfig.default === void 0 ? void 0 : (_appConfig$fontawesom2 = _emberGetConfig.default.fontawesome) === null || _appConfig$fontawesom2 === void 0 ? void 0 : _appConfig$fontawesom2.defaultPrefix) !== null && _appConfig$fontawesom !== void 0 ? _appConfig$fontawesom : 'fas';

    if (!icon) {
      return {
        prefix: defaultPrefix,
        iconName: null
      };
    }

    if (typeof icon === 'object' && icon.prefix && icon.iconName) {
      return icon;
    }

    if (_fontawesomeSvgCore.parse.icon) {
      if (typeof prefix === 'string' && typeof icon === 'string') {
        return _fontawesomeSvgCore.parse.icon({
          prefix: prefix,
          iconName: icon
        });
      }

      if (typeof icon === 'string') {
        return _fontawesomeSvgCore.parse.icon({
          prefix: defaultPrefix,
          iconName: icon
        });
      }
    }

    if (typeof prefix === 'string' && typeof icon === 'string') {
      return {
        prefix: prefix,
        iconName: icon
      };
    }

    if (typeof icon === 'string') {
      return {
        prefix: defaultPrefix,
        iconName: icon
      };
    }
  }

  class FaIconComponent extends _component.default {
    get content() {
      var _this$abstractIcon$ch, _this$abstractIcon;

      const children = (_this$abstractIcon$ch = (_this$abstractIcon = this.abstractIcon) === null || _this$abstractIcon === void 0 ? void 0 : _this$abstractIcon.children) !== null && _this$abstractIcon$ch !== void 0 ? _this$abstractIcon$ch : [];
      const html = children.reduce((acc, cur) => {
        return "".concat(acc).concat((0, _fontawesomeSvgCore.toHtml)(cur));
      }, '');
      return Ember.String.htmlSafe(html);
    }

    get safeStyle() {
      return this.iconAttributes.style ? Ember.String.htmlSafe("".concat(this.iconAttributes.style)) : undefined;
    }

    get iconExists() {
      return Boolean(this.abstractIcon);
    }

    get flipHorizontal() {
      return this.args.flip === 'horizontal' || this.args.flip === 'both';
    }

    get flipVertical() {
      return this.args.flip === 'vertical' || this.args.flip === 'both';
    }

    get classList() {
      let classes = {
        'fa-spin': this.args.spin,
        'fa-pulse': this.args.pulse,
        'fa-fw': this.args.fixedWidth,
        'fa-border': this.args.border,
        'fa-li': this.args.listItem,
        'fa-flip-horizontal': this.flipHorizontal,
        'fa-flip-vertical': this.flipVertical,
        ["fa-".concat(this.args.size)]: this.args.size,
        ["fa-rotate-".concat(this.args.rotation)]: this.args.rotation,
        ["fa-pull-".concat(this.args.pull)]: this.args.pull
      };
      return Object.keys(classes).filter(key => classes[key]);
    }

    get abstractIcon() {
      var _this$args$symbol;

      const iconLookup = normalizeIconArgs(this.args.prefix, this.args.icon);
      const classes = objectWithKey('classes', this.classList);
      const transform = objectWithKey('transform', typeof this.args.transform === 'string' ? _fontawesomeSvgCore.parse.transform(this.args.transform) : this.args.transform);
      const mask = objectWithKey('mask', normalizeIconArgs(null, this.args.mask));
      const symbol = (_this$args$symbol = this.args.symbol) !== null && _this$args$symbol !== void 0 ? _this$args$symbol : false;
      let title = this.args.title ? "".concat(this.args.title) : null;
      const o = Object.assign({}, classes, transform, mask, {
        symbol,
        title
      });
      const renderedIcon = (0, _fontawesomeSvgCore.icon)(iconLookup, o);

      if (!renderedIcon) {
        console.warn("Could not find icon: iconName=".concat(iconLookup.iconName, ", prefix=").concat(iconLookup.prefix, ". You may need to add it to your icons.js."));
        return null;
      }

      return renderedIcon.abstract[0];
    }

    get iconAttributes() {
      var _this$abstractIcon$at, _this$abstractIcon2;

      return (_this$abstractIcon$at = (_this$abstractIcon2 = this.abstractIcon) === null || _this$abstractIcon2 === void 0 ? void 0 : _this$abstractIcon2.attributes) !== null && _this$abstractIcon$at !== void 0 ? _this$abstractIcon$at : {};
    }

    get dataPrefix() {
      return this.iconAttributes['data-prefix'];
    }

    get dataIcon() {
      return this.iconAttributes['data-icon'];
    }

    get dataFaTransform() {
      return this.iconAttributes['data-fa-transform'];
    }

    get dataFaMask() {
      return this.iconAttributes['data-fa-mask'];
    }

    get dataFaProcessed() {
      return this.iconAttributes['data-fa-processed'];
    }

    get ariaHidden() {
      return this.iconAttributes['aria-hidden'];
    }

    get ariaLabelledBy() {
      return this.iconAttributes['aria-labelledby'];
    }

    get viewBox() {
      var _this$abstractIcon$at2, _this$abstractIcon3, _this$abstractIcon3$a;

      return (_this$abstractIcon$at2 = (_this$abstractIcon3 = this.abstractIcon) === null || _this$abstractIcon3 === void 0 ? void 0 : (_this$abstractIcon3$a = _this$abstractIcon3.attributes) === null || _this$abstractIcon3$a === void 0 ? void 0 : _this$abstractIcon3$a.viewBox) !== null && _this$abstractIcon$at2 !== void 0 ? _this$abstractIcon$at2 : '0 0 448 512';
    }

  }

  _exports.default = FaIconComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FaIconComponent);
});