define("x-filters/templates/components/x-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mvKHpU4X",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[8,\"x-check\",[],[[\"@value\",\"@checked\",\"@action\",\"@disabled\"],[[34,5],[34,5,[\"active\"]],[30,[36,0],[[32,0],\"filterToggle\"],null],[34,5,[\"readOnly\"]]]],null],[2,\"\\n\\n  \"],[1,[35,5,[\"title\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,9],[[35,5,[\"active\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,8],[[30,[36,7],[[35,6]],null]],[[\"model\",\"eventList\",\"originOrgId\",\"forceRequisitions\",\"type\",\"updateFilters\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"updateFilters\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"type\",\"forceRequisitions\",\"originOrgId\",\"eventList\",\"model\",\"componentName\",\"dasherize\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "x-filters/templates/components/x-filter.hbs"
    }
  });

  _exports.default = _default;
});