define("x-gauge/components/x-gauge", ["exports", "x-gauge/templates/components/x-gauge"], function (_exports, _xGauge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COLORS = {
    charcoal: '#666',
    blue: '#00a1de',
    gold: '#FFAB60'
  };

  var _default = Ember.Component.extend({
    layout: _xGauge.default,
    tagName: 'span',
    classNames: ['x-gauge'],
    colors: Ember.computed('level', function () {
      let level = this.get('level');

      if (level > 6) {
        return {
          bg: 'lightgrey',
          fg: COLORS.charcoal,
          class: ''
        };
      } else if (level > 2) {
        return {
          bg: COLORS.charcoal,
          fg: COLORS.gold,
          class: 'gold'
        };
      } else {
        return {
          bg: COLORS.charcoal,
          fg: COLORS.blue,
          class: 'blue'
        };
      }
    }),
    coords: Ember.computed('radians', function () {
      let radians = this.get('radians');
      let x = Math.cos(radians);
      let y = Math.sin(radians);
      let right = radians < Math.PI ? {
        x,
        y
      } : {
        x: -1,
        y: 0
      };
      return {
        left: {
          x,
          y
        },
        right
      };
    }),
    radians: Ember.computed('percent', function () {
      let percent = this.get('percent');
      return 2 * Math.PI * percent / 100;
    })
  });

  _exports.default = _default;
});