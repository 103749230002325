define("vikus-ajax/utils/ajax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    $,
    V
  } = window;

  var _default = Ember.Object.create({
    get(url) {
      return this.request('GET', url);
    },

    post(url, data) {
      return this.request('POST', url, data);
    },

    put(url, data) {
      return this.request('PUT', url, data);
    },

    delete(url, data) {
      return this.request('DELETE', url, data);
    },

    file(url, data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: this.getUrl(url),
          data,
          contentType: false,
          processData: false
        }).then(resolve, jqXHR => {
          // Nested for Ember Error Route
          if (jqXHR.status === 503) {
            window.location = '/';
          }

          let error = {
            errors: [{
              status: "".concat(jqXHR.status),
              title: jqXHR.statusText,
              detail: jqXHR.responseText,
              url
            }]
          };
          reject(error);
        });
      });
    },

    request(type, url, data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        $.ajax({
          type,
          url: this.getUrl(url),
          contentType: 'application/json',
          data: JSON.stringify(data)
        }).then(resolve, jqXHR => {
          if (jqXHR.status === 503) {
            window.location = '/';
          } // Nested for Ember Error Route


          let error = {
            errors: [{
              status: "".concat(jqXHR.status),
              title: jqXHR.statusText,
              detail: JSON.stringify(data),
              message: jqXHR.responseText,
              url
            }]
          };
          reject(error);
        });
      });
    },

    getUrl(url) {
      if (V && V.version) {
        // Console, applicants
        return "/".concat(V.version, "/api/").concat(url);
      } // Admin


      return "/api/".concat(url);
    }

  });

  _exports.default = _default;
});