define("x-button/components/x-button", ["exports", "x-button/templates/components/x-button"], function (_exports, _xButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xButton.default,
    tagName: 'button',
    attributeBindings: ['disabled', 'type'],
    type: 'button',
    disabled: Ember.computed.or('isDisabled', 'isLoading'),

    click(event) {
      // Click action is optional inside a form
      if (this.attrs.click) {
        this.attrs.click(event);
      }
    }

  });

  _exports.default = _default;
});