define("x-gauge/utils/progress", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = progress;

  function progress(date) {
    let diff = Math.floor((0, _moment.default)().diff(date, 'days', true));

    if (diff < 0) {
      return {
        progress: 0,
        diff: -1
      };
    }

    if (diff < 90) {
      return {
        level: 0,
        goal: 90,
        progress: Math.floor(diff / 90 * 100),
        diff,
        units: 'day',
        goalUnits: 'day'
      };
    }

    diff = Math.floor((0, _moment.default)().diff(date, 'months', true));

    if (diff < 6) {
      return {
        level: 1,
        goal: 6,
        progress: Math.floor(diff / 6 * 100),
        diff,
        units: 'month',
        goalUnits: 'month'
      };
    }

    let diffMonths = diff;
    diff = (0, _moment.default)().diff(date, 'years', true);
    let progress = Math.floor(diff % 1 * 100);
    let level = Math.floor(diff) + 2;
    let goal = level - 1;

    if (level >= 12) {
      goal = 10;
      level = 12;
      progress = 100;
    }

    return {
      level,
      progress,
      diff: diff < 1 ? diffMonths : Math.floor(diff),
      goal,
      units: diff < 1 ? 'month' : 'year',
      goalUnits: 'year'
    };
  }
});