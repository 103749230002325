define("v-avatar/components/avatar-img", ["exports", "v-avatar/templates/components/avatar-img"], function (_exports, _avatarImg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _avatarImg.default,
    tagName: ''
  });

  _exports.default = _default;
});