define("x-modal/components/x-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://github.com/emberjs/ember.js/issues/18591
  const {
    $
  } = window;

  var _default = Ember.Component.extend({
    buttons: null,
    renderInPlace: true,
    disableBackdrop: true,
    closeButton: Ember.computed('buttons.length', function () {
      return this.buttons[this.buttons.length - 1];
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      let buttons = Object.keys(this.attrs).join(' ').match(/\wAction/g).sort().map(attr => {
        let letter = attr.charAt(0);
        return {
          label: this.get("".concat(letter, "Label")),
          disabled: this.get("".concat(letter, "Disabled")),
          classNames: this.get("".concat(letter, "Class")),
          action: this.get("".concat(letter, "Action"))
        };
      }).filter(x => Ember.isPresent(x.label)); // Do not re-render the buttons if they haven't changed

      let differentButtons = buttons.some((x, i) => {
        if (!this.buttons) {
          return true;
        }

        let {
          classNames,
          disabled,
          label
        } = this.buttons[i];
        return x.classNames !== classNames || x.disabled !== disabled || x.label !== label;
      });

      if (differentButtons) {
        this.set('buttons', buttons);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.run.next(this, () => {
        let target = this.findTarget();
        target.modal().on('hidden.bs.modal', () => {
          let modal = document.querySelector('.modal.in');

          if (modal) {
            document.body.classList.add('modal-open');
          }

          target.off('hidden.bs.modal').off('shown.bs.modal');
        }).on('shown.bs.modal', () => {
          let autofocus = this.element.querySelector('[autofocus]');

          if (autofocus) {
            autofocus.focus();
          }

          if (this.onShown) {
            this.onShown(this.element);
          }
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      this.findTarget().modal('hide');
    },

    findTarget() {
      let target = this.renderInPlace ? this.element : document.querySelector('#modal-destination');
      return $(target.querySelector('.modal'));
    }

  });

  _exports.default = _default;
});