define("v-share-job/components/direct-link", ["exports", "v-share-job/templates/components/direct-link"], function (_exports, _directLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _directLink.default,
    link: null,
    canCopy: document.queryCommandSupported('copy'),
    actions: {
      copy() {
        document.querySelector('#direct-link').select();
        document.execCommand('copy');
        this.close();

        if (this.notify) {
          this.notify.success('Link copied to clipboard');
        }
      }

    }
  });

  _exports.default = _default;
});