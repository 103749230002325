define("error-handler/services/error", ["exports", "bugsnag"], function (_exports, _bugsnag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    $
  } = window;

  var _default = Ember.Service.extend({
    initialize() {
      Error.stackTraceLimit = 50;

      Ember.onerror = error => this.handle(error);
    },

    handle(error, transition) {
      let sendBugsnag = true;
      let reload = false;
      let goHome = Ember.isPresent(transition);
      let status = error.errors && error.errors[0] && error.errors[0].status;

      switch (status) {
        case '0':
          return;

        case '401':
          sendBugsnag = false;

          if (this.isSessionExpired()) {
            reload = true;
          } else if (window.showLoggedOutDialog) {
            return window.showLoggedOutDialog();
          }

          break;

        case '403':
        case '404':
          sendBugsnag = false;
          goHome = true;
          break;

        case '410':
          sendBugsnag = false;
          reload = true;
          break;

        case '428':
          sendBugsnag = false;
          goHome = true;
          break;

        case '503':
          reload = true;
          break;
      }

      if (sendBugsnag) {
        this.logBugsnag(error);
      }

      if (reload) {
        window.location.reload();
      } else if (goHome) {
        this.goHome(transition, status);
      }
    },

    goHome(transition, status) {
      if (transition) {
        transition.send(false, 'goHome', transition, status);
      } else {
        Ember.getOwner(this).lookup('route:application').send('goHome');
      }
    },

    isSessionExpired() {
      let auth = $.ajaxSettings.headers && $.ajaxSettings.headers.Authorization;
      let parts = auth && auth.split('.');
      let body = parts && parts.length === 3 && parts[1];
      let decoded = body && window.atob(body);
      let exp = decoded && JSON.parse(decoded).exp;
      return exp && exp < Date.now() / 1000;
    },

    logBugsnag(error) {
      let err = error.errors && error.errors[0];
      let message = Ember.isPresent(err) ? "".concat(err.status, " - ").concat(err.title) : error.message;
      console.error(message, error);

      if (this.isDev) {
        return;
      }

      this.bugsnagClient.request = {
        api: err ? err.url : error.url,
        body: err ? err.detail : error.detail
      };
      this.bugsnagClient.metaData = {
        error: message
      };
      this.bugsnagClient.notify(new _bugsnag.Bugsnag.Report(error.name, message, _bugsnag.Bugsnag.Report.getStacktrace(error), {
        severity: 'error',
        unhandled: true,
        severityReason: {
          type: 'unhandledException'
        }
      }, err ? "Got ".concat(err.status, " - ").concat(err.url) : error));
    },

    setUser(user) {
      this.bugsnagClient.user = user;
    }

  });

  _exports.default = _default;
});