define("x-pdf/components/x-pdf", ["exports", "x-pdf/templates/components/x-pdf", "x-pdf/utils/pdfjs"], function (_exports, _xPdf, _pdfjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    $
  } = window;

  var _default = Ember.Component.extend({
    layout: _xPdf.default,
    url: null,
    document: null,
    eventBus: null,
    isLoading: true,
    isError: false,
    width: 0,

    didInsertElement() {
      this._super(...arguments);

      this.renderPdf().then(() => $(window).on('resize.pdf', Ember.run.bind(this, this.resize)));
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.setProperties({
        isLoading: true,
        isError: false
      });
      this.renderPdf();
    },

    willDestroyElement() {
      $(window).off('resize.pdf');
    },

    resize() {
      let width = this.element && this.element.offsetWidth;

      if (width && width !== this.width) {
        Ember.run.debounce(this, this.renderPages, 150);
      }
    },

    renderPdf() {
      return (0, _pdfjs.default)().then(pdfjsLib => {
        pdfjsLib.getDocument({
          httpHeaders: $.ajaxSettings.headers,
          url: this.url,
          worker: pdfjsLib.HCSWorker
        }).promise.then(pdf => {
          if (this.element) {
            this.set('document', pdf);
            this.renderPages();
          }
        }, () => this.setProperties({
          isLoading: false,
          isError: true
        }));
      });
    },

    renderPages() {
      let {
        pdfjsViewer
      } = window;
      this.setProperties({
        eventBus: new pdfjsViewer.EventBus(),
        isLoading: true
      }); // Ensure user hasn't transitioned away

      let pdfBody = this.element.querySelector('.pdf-body');
      let pdf = this.get('document');

      if (Ember.isNone(pdfBody) || Ember.isNone(pdf)) {
        this.set('isLoading', false);
        return;
      }

      while (pdfBody.firstChild) {
        pdfBody.removeChild(pdfBody.firstChild);
      }

      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then(Ember.run.bind(this, this.renderPage));
      }

      this.set('isLoading', false);
      let width = this.element && this.element.offsetWidth;
      this.set('width', width);
    },

    renderPage(page) {
      let {
        pdfjsLib,
        pdfjsViewer
      } = window;
      let parent = this.element.querySelector('.pdf-body');
      let {
        offsetWidth
      } = this.element;
      let container = document.createElement('div');
      container.className = 'pdf-page';
      let {
        width
      } = page.getViewport({
        scale: 1.0
      });
      let ratio = Math.floor(offsetWidth / width * 99) / 100;
      let viewport = page.getViewport({
        scale: ratio
      });
      let canvas = document.createElement('canvas'); // The changes in the next line need to be removed once Microsoft or Google resolves this issue:
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1404710

      let canvasContext = canvas.getContext('2d', {
        alpha: false
      }); // Force higher DPI if supported

      if (devicePixelRatio > ratio) {
        let transform = [devicePixelRatio, 0, 0, devicePixelRatio, 0, 0];
        canvas.height = Math.floor(viewport.height * devicePixelRatio);
        canvas.width = Math.floor(viewport.width * devicePixelRatio);
        canvas.style.height = "".concat(viewport.height, "px");
        canvas.style.width = "".concat(viewport.width, "px");
        page.render({
          canvasContext,
          transform,
          viewport
        });
      } else {
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render({
          canvasContext,
          viewport
        });
      }

      let textContainer = document.createElement('div');
      textContainer.className = 'text-layer';
      page.getTextContent().then(textContent => pdfjsLib.renderTextLayer({
        textContent,
        viewport,
        container: textContainer
      }));
      let annotationContainer = document.createElement('div');
      annotationContainer.className = 'annotation-layer';
      page.getAnnotations().then(annotations => pdfjsLib.AnnotationLayer.render({
        viewport: viewport.clone({
          dontFlip: true
        }),
        annotations,
        renderInteractiveForms: false,
        linkService: new pdfjsViewer.PDFLinkService({
          eventBus: this.eventBus,
          externalLinkTarget: pdfjsLib.LinkTarget.BLANK
        }),
        div: annotationContainer,
        page
      }));
      container.appendChild(canvas);
      container.appendChild(textContainer);
      container.appendChild(annotationContainer);
      parent.appendChild(container);
    }

  });

  _exports.default = _default;
});