define("v-pagination/components/v-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    page: 1,
    totalPages: 1,
    maxPages: 9,
    hasPages: Ember.computed.gt('totalPages', 1),
    hasPrev: Ember.computed.gt('page', 1),
    hasNext: Ember.computed('page', 'totalPages', function () {
      return this.get('page') < this.get('totalPages');
    }),
    pages: Ember.computed('page', 'totalPages', function () {
      let page = this.get('page');
      let totalPages = this.get('totalPages');
      let pages = [];
      pages.push(page);
      let prev = page;
      let next = page;

      while (pages.length < this.get('maxPages') && (prev > 1 || next < totalPages)) {
        if (prev-- > 1) {
          pages.unshift(prev);
        }

        if (next++ < totalPages) {
          pages.push(next);
        }
      }

      return pages;
    }),
    actions: {
      changePage(page) {
        if (this.attrs.change) {
          this.attrs.change(page);
        } else {
          this.set('page', page);
        }
      },

      incrementPage(value) {
        let page = this.get('page');

        if (value < 0 && this.get('hasPrev')) {
          this.send('changePage', page - 1);
        } else if (value > 0 && this.get('hasNext')) {
          this.send('changePage', page + 1);
        }
      }

    }
  });

  _exports.default = _default;
});