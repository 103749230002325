define("v-avatar/templates/components/avatar-img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AXLHZ5Pu",
    "block": "{\"symbols\":[\"@url\",\"@className\",\"@titlePlacement\",\"@name\"],\"statements\":[[10,\"span\"],[14,0,\"avatar\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[32,1]],[15,0,[32,2]],[14,\"data-toggle\",\"tooltip\"],[15,\"data-placement\",[32,3]],[15,\"data-original-title\",[32,4]],[15,\"alt\",[32,4]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "v-avatar/templates/components/avatar-img.hbs"
    }
  });

  _exports.default = _default;
});