define("x-filters/models/filters/number-compare-filter", ["exports", "@ember-data/model", "x-filters/models/filters/base"], function (_exports, _model, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    type: 'filters/numberCompareFilter',
    value: (0, _model.attr)('number'),
    operator: (0, _model.attr)('number'),
    min: (0, _model.attr)('number'),
    max: (0, _model.attr)('number'),
    searchImmediately: true,

    serialize() {
      let obj = this._super(...arguments);

      obj.operator = this.operator;
      obj.value = this.value;
      return obj;
    },

    modelFromParam(param) {
      this.setProperties({
        operator: param.operator,
        value: param.value
      });
    }

  });

  _exports.default = _default;
});