define("v-avatar-cropper/components/v-avatar-cropper", ["exports", "v-avatar-cropper/templates/components/v-avatar-cropper"], function (_exports, _vAvatarCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    $
  } = window;
  const CROPPER_PATH = '//cdn.healthcaresource.com/assets/cropper/4.0.0';
  const MinSize = 220;

  var _default = Ember.Component.extend({
    layout: _vAvatarCropper.default,
    img: null,

    didInsertElement() {
      this._super(...arguments);

      if (!$.fn.cropper) {
        this.loadCropper().then(() => this.showCropper());
      } else {
        this.showCropper();
      }
    },

    willDestroyElement() {
      let image = this.get('img');

      if (image) {
        image.cropper('destroy');
      }
    },

    actions: {
      save() {
        let image = this.get('img').cropper('getCroppedCanvas').toDataURL();
        this.attrs.save(image);
      }

    },

    loadCropper() {
      return new Ember.RSVP.Promise(resolve => {
        let js = document.createElement('script');
        js.type = 'text/javascript';
        js.src = "".concat(CROPPER_PATH, "/cropper.min.js");
        js.onload = resolve;
        let css = document.createElement('link');
        css.href = "".concat(CROPPER_PATH, "/cropper.min.css");
        css.rel = 'stylesheet';
        document.getElementsByTagName('head').item(0).appendChild(css).appendChild(js);
      });
    },

    showCropper() {
      let file = this.get('avatar');
      let reader = new FileReader();
      reader.readAsDataURL(file.data);

      reader.onload = e => this.selectFile(e);
    },

    selectFile(event) {
      let img = this.element.querySelector('img');
      img.src = event.target.result;

      img.onload = () => {
        // Wait for the modal fade-in to finish
        Ember.run.later(this, () => {
          let {
            height,
            width
          } = img;

          if (width < MinSize || height < MinSize) {
            this.attrs.close();
            this.notify.error('Image is too small');
            return;
          }

          let options = {
            viewMode: 3,
            aspectRatio: 1,
            minContainerWidth: MinSize,
            minContainerHeight: MinSize,
            minCropBoxWidth: MinSize,
            minCropBoxHeight: MinSize,
            movable: false,
            zoomable: false
          };
          let selection = $(this.element.querySelector('.cropper-container'));

          if (width < selection.width()) {
            selection.css('width', width);
            options.autoCropArea = 1;
          }

          if (height < selection.height()) {
            selection.css('height', height);
            options.autoCropArea = 1;
          }

          let image = $(this.element.querySelector('img'));
          this.set('img', image);
          image.cropper(options);
        }, 500);
      };
    }

  });

  _exports.default = _default;
});