define("x-filters/models/filters/toggle-filter", ["exports", "x-filters/models/filters/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    type: 'filters/toggleFilter',
    value: true,
    searchImmediately: true,

    modelFromParam() {}

  });

  _exports.default = _default;
});