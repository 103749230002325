define("ember-atoms/components/checkbox", ["exports", "ember-atoms/components/toggle"], function (_exports, _toggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label class="hcs-checkbox {{if this.isIndeterminate "indeterminate"}}" ...attributes>
    <input
      checked={{@isChecked}}
      class="absolute opacity-0"
      disabled={{@isDisabled}}
      type="checkbox"
      {{on "click" this.onClick}}
    >
    <span class="faux-checkbox bg-white cursor-pointer absolute rounded block w-5 h-5" />
    <span class="hcs-label">
      {{!-- Allow triple-curlies for hardcoded HTML --}}
      {{! template-lint-disable no-triple-curlies }}
      {{{yield}}}
    </span>
  </label>
  
  */
  {"id":"64ikz8wn","block":"{\"symbols\":[\"&attrs\",\"@isChecked\",\"@isDisabled\",\"&default\"],\"statements\":[[11,\"label\"],[16,0,[31,[\"hcs-checkbox \",[30,[36,0],[[32,0,[\"isIndeterminate\"]],\"indeterminate\"],null]]]],[17,1],[12],[2,\"\\n  \"],[11,\"input\"],[16,\"checked\",[32,2]],[24,0,\"absolute opacity-0\"],[16,\"disabled\",[32,3]],[24,4,\"checkbox\"],[4,[38,1],[\"click\",[32,0,[\"onClick\"]]],null],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"faux-checkbox bg-white cursor-pointer absolute rounded block w-5 h-5\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"hcs-label\"],[12],[2,\"\\n\"],[2,\"    \"],[18,4,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}","meta":{"moduleName":"ember-atoms/components/checkbox.hbs"}});

  /**
    `Checkbox` is a control used when there is a list of options
    and the user may select multiple options, including all or none.
  
    ```js
      <Checkbox
        @isChecked={{this.isChecked}}
        @isDisabled={{this.isDisabled}}
        @onClick={{this.checkSomething}}
      >
        I like snow
      </Checkbox>
    ```
  
    _Note:_ The `indeterminate` state can be obtained by setting `isChecked` to `null`.
  
    @class Checkbox
   */
  class Checkbox extends _toggle.default {
    get isIndeterminate() {
      return this.args.isChecked === null;
    }

  }

  _exports.default = Checkbox;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Checkbox);
});