define("x-page/components/x-combo", ["exports", "@glimmer/component", "x-page/utils/states"], function (_exports, _component, _states) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.isVisible}}
    <div class="form-group combo {{if @model.error "has-error"}}">
      <label>
        {{!-- Allow triple-curlies for html content from api --}}
        {{!-- template-lint-disable triple-curlies --}}
        {{{@model.label}}}
      </label>
  
      {{#if @readOnly}}
        <div>{{or @model.value.name @model.value}}</div>
      {{else}}
        <XSelect
          @model={{this.options}}
          @value={{@model.value}}
          @placeholder=""
          @openOnFocus={{if @model.comboValue true false}}
          @dropdown={{this.dropdown}}
          @labelKey="name"
          @valueKey="id"
          @onSelect={{action (mut @model.value)}}
        />
      {{/if}}
  
      <FieldError @model={{@model.error}} />
    </div>
  {{/if}}
  
  */
  {
    "id": "Ej2sHKMw",
    "block": "{\"symbols\":[\"@model\",\"@readOnly\"],\"statements\":[[6,[37,0],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"form-group combo \",[30,[36,0],[[32,1,[\"error\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n\"],[2,\"      \"],[2,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[1,[30,[36,3],[[32,1,[\"value\",\"name\"]],[32,1,[\"value\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"x-select\",[],[[\"@model\",\"@value\",\"@placeholder\",\"@openOnFocus\",\"@dropdown\",\"@labelKey\",\"@valueKey\",\"@onSelect\"],[[32,0,[\"options\"]],[32,1,[\"value\"]],\"\",[30,[36,0],[[32,1,[\"comboValue\"]],true,false],null],[32,0,[\"dropdown\"]],\"name\",\"id\",[30,[36,2],[[32,0],[30,[36,1],[[32,1,[\"value\"]]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"field-error\",[],[[\"@model\"],[[32,1,[\"error\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"mut\",\"action\",\"or\"]}",
    "meta": {
      "moduleName": "x-page/components/x-combo.hbs"
    }
  });

  const Months = [{
    id: '1',
    name: 'January'
  }, {
    id: '2',
    name: 'February'
  }, {
    id: '3',
    name: 'March'
  }, {
    id: '4',
    name: 'April'
  }, {
    id: '5',
    name: 'May'
  }, {
    id: '6',
    name: 'June'
  }, {
    id: '7',
    name: 'July'
  }, {
    id: '8',
    name: 'August'
  }, {
    id: '9',
    name: 'September'
  }, {
    id: '10',
    name: 'October'
  }, {
    id: '11',
    name: 'November'
  }, {
    id: '12',
    name: 'December'
  }];

  class PageComboComponent extends _component.default {
    get dropdown() {
      var _this$args$model$comb;

      return ((_this$args$model$comb = this.args.model.comboType) === null || _this$args$model$comb === void 0 ? void 0 : _this$args$model$comb.startsWith('USSTATES')) ? 'select-dropdown-state' : 'select-dropdown';
    }

    get options() {
      let {
        comboType,
        comboValue
      } = this.args.model;

      if (comboType === 'LONGMONTHS') {
        return Months;
      } else if (comboType === 'USSTATES') {
        return _states.default;
      } else if (comboType === 'USSTATESOTHER') {
        return [{
          id: 'ZZ',
          name: 'Outside US'
        }, ..._states.default];
      }

      return comboValue;
    }

  }

  _exports.default = PageComboComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PageComboComponent);
});