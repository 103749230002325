define("ember-keyboard/deprecated/components/keyboard-press", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* This is a component to allow an Ember template to declaratively specify an
   * action to run when a particular key event occurs. In the following example,
   * we trigger an action button when the "/" key is pressed:
   *
   * {{keyboard-press
   *    key='Slash'
   *    onDown=(action 'onSlash')
   *  }}
   */
  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, _emberKeyboard.EKOnInsertMixin, {
    didInsertElement() {
      (false && !(false) && Ember.deprecate('The `keyboard-press` component of ember-keyboard is deprecated. Please use the `on-key` helper instead.', false, {
        id: 'ember-keyboard.keyboard-press',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#keyboard-press'
      }));

      if (this.priority) {
        this.set('keyboardPriority', this.priority);
      }

      this._super(...arguments);

      if (this.onPress) {
        this.on((0, _emberKeyboard.keyPress)(this.key), this.onPress);
      }

      if (this.onDown) {
        this.on((0, _emberKeyboard.keyDown)(this.key), this.onDown);
      }

      if (this.onUp) {
        this.on((0, _emberKeyboard.keyUp)(this.key), this.onUp);
      }
    }

  });

  _exports.default = _default;
});