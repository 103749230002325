define("x-video/components/x-video-stream", ["exports", "x-video/templates/components/x-video-stream"], function (_exports, _xVideoStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xVideoStream.default,
    videoId: null,
    hasVideo: null,
    attendeeId: null,
    video: null,

    init() {
      this._super(...arguments);

      this.setProperties({
        hasVideo: Ember.isPresent(this.tileId),
        videoId: "video-stream-".concat(this.attendeeId)
      });
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.connect && this.hasVideo) {
        let element = document.getElementById(this.videoId);
        this.connect(this.tileId, element);
      }
    },

    didUpdateAttrs() {
      this._super(...arguments);

      if (this.connect && !this.hasVideo && this.tileId) {
        let element = document.getElementById(this.videoId);
        this.connect(this.tileId, element);
      } else if (this.disconnect && this.hasVideo && !this.tileId) {
        this.disconnect(this.tileId);
      }

      this.set('hasVideo', Ember.isPresent(this.tileId));
    }

  });

  _exports.default = _default;
});