define("x-filters/models/filters/combo-filter", ["exports", "@ember-data/model", "moment", "x-filters/models/filters/base"], function (_exports, _model, _moment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DataType = {
    Boolean: 2,
    Date: 4,
    Number: 8,
    Text: 22
  };

  var _default = _base.default.extend({
    value: (0, _model.attr)('string'),
    possibilities: (0, _model.attr)(),
    underlyingType: (0, _model.attr)('number'),

    toggle(nowChecked) {
      this._super(...arguments);

      if (!nowChecked) {
        this.set('value', null);
      }
    },

    serialize() {
      let obj = {};

      if (Ember.isEmpty(this.field)) {
        obj.id = this.id;
      }

      let value = this.get('value.id') || this.value;
      obj.value = value;
      obj.field = this.field; // Pretend we are other filters based on underlying type

      let type = this.underlyingType;

      switch (type) {
        case DataType.Boolean:
          {
            obj.type = 'filters/toggleFilter';
            obj.value = this.toBool(value);
            break;
          }

        case DataType.Date:
          {
            obj.type = 'filters/dateRangeFilter';
            let diff = (0, _moment.default)(value).diff((0, _moment.default)(), 'days');
            obj.value = {
              from: diff,
              to: 0
            };
            break;
          }

        case DataType.Number:
          {
            obj.type = 'filters/numberCompareFilter';
            obj.value = Number(value);
            obj.operator = 0;
            break;
          }

        case DataType.Text:
        default:
          {
            obj.type = 'filters/textFilter';
          }
      }

      return obj;
    },

    modelFromParam(param) {
      let options = Ember.A(this.possibilities);

      if (this.underlyingType === DataType.Date) {
        let {
          from
        } = param.value;
        let fromDate = (0, _moment.default)().add(from, 'days');
        let momentOptions = options.map(orig => ({
          orig,
          date: (0, _moment.default)(orig.id)
        }));
        let target = momentOptions.find(x => fromDate.diff(x.date, 'days') === 0);
        this.set('value', target.orig);
      } else if (this.underlyingType === DataType.Boolean) {
        let isOn = this.toBool(param.value);
        let correctOption = options[isOn ? 0 : 1];
        this.set('value', correctOption);
      } else {
        this.set('value', options.find(x => x.id === param.value.toString()));
      }
    },

    toBool(obj) {
      if (Ember.isEmpty(obj)) {
        return false;
      } else if (typeof obj === 'boolean') {
        return obj;
      }

      let str = obj.toLowerCase();
      return str === 'true' || str === '1' || str === 'yes';
    }

  });

  _exports.default = _default;
});