define("x-filters/models/filters/text-filter", ["exports", "@ember-data/model", "x-filters/models/filters/base"], function (_exports, _model, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    type: 'filters/textFilter',
    value: (0, _model.attr)('string'),
    inputMask: (0, _model.attr)('string'),
    placeholder: (0, _model.attr)('string'),
    maxLength: (0, _model.attr)('number'),

    toggle(nowChecked) {
      this._super(...arguments);

      if (!nowChecked) {
        this.set('value', '');
      }
    },

    serialize() {
      let obj = this._super(...arguments);

      obj.value = this.value;
      return obj;
    }

  });

  _exports.default = _default;
});