define("x-textarea/components/x-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    classNames: ['x-textarea'],
    minRows: 0,

    init() {
      this._super(...arguments);

      this.set('minRows', this.get('rows'));
    },

    didInsertElement() {
      this._super(...arguments);

      this.resize();
    },

    input() {
      Ember.run.debounce(this, this.resize, 100);
    },

    keyDown(event) {
      let isCmd = event.ctrlKey || event.metaKey;

      if (this.submit && event.keyCode === 13 && isCmd) {
        event.preventDefault();
        this.submit();
      }
    },

    resize() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      let minRows = this.get('minRows');
      let maxRows = this.get('maxRows'); // Reset rows instead of decreasing

      this.element.rows = minRows;
      let runs = 0;

      while (runs++ <= maxRows && this.element.clientHeight !== this.element.scrollHeight) {
        if (this.element.rows === maxRows) {
          break;
        }

        if (this.element.clientHeight < this.element.scrollHeight) {
          this.element.rows++;
        }
      }
    }

  });

  _exports.default = _default;
});