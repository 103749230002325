define("x-filters/utils/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadFilters = loadFilters;

  function loadFilters(queryString, filterObjects, store) {
    filterObjects.filter(x => !x.readOnly).forEach(x => x.toggle(false));
    filterObjects.filter(x => Ember.isEmpty(x.value)).forEach(x => Ember.set(x, 'active', false));

    if (Ember.isEmpty(queryString) || queryString === '[]') {
      filterObjects.setEach('active', false);
      return;
    }

    JSON.parse(queryString).forEach(x => {
      let filter = filterObjects.find(y => y.id === "".concat(x.id));

      if (Ember.isEmpty(filter) && Ember.isPresent(x.field)) {
        filter = filterObjects.find(y => y.field === "".concat(x.field));
      }

      filter.modelFromParam(x, store);

      if (Ember.isEmpty(filter) || filter.readOnly) {
        return;
      }

      filter.set('active', true);
    });
  }
});