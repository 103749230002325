define("ember-keyboard/helpers/if-key", ["exports", "ember-keyboard/utils/is-key", "ember-keyboard/utils/listener-name"], function (_exports, _isKey, _listenerName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function ifKey([keyCombo, callback]
  /*, hash*/
  ) {
    return function (event) {
      (false && !(typeof callback === 'function') && Ember.assert('ember-keyboard: You must pass a function as the second argument to the `if-key` helper', typeof callback === 'function'));
      (false && !(event instanceof KeyboardEvent) && Ember.assert('ember-keyboard: The `if-key` helper expects to be invoked with a KeyboardEvent', event instanceof KeyboardEvent));

      if ((0, _isKey.default)((0, _listenerName.default)(event.type, keyCombo), event)) {
        callback(event);
      }
    };
  });

  _exports.default = _default;
});