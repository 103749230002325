define("ember-atoms/components/radio", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label class="hcs-radio relative inline-block mb-2 mr-4 align-middle leading-none" ...attributes>
    <input
      checked={{this.isChecked}}
      class="absolute opacity-0"
      disabled={{@isDisabled}}
      name={{@group}}
      type="radio"
      value={{@value}}
      {{on "click" (fn this.onClick @value)}}
    >
    <span class="hcs-radio-pseudo bg-white cursor-pointer absolute rounded-full block h-5 w-5" />
    <span class="hcs-label mb-0 cursor-pointer">
      {{yield}}
    </span>
  </label>
  
  */
  {"id":"8PUfvS8G","block":"{\"symbols\":[\"&attrs\",\"@isDisabled\",\"@group\",\"@value\",\"&default\"],\"statements\":[[11,\"label\"],[24,0,\"hcs-radio relative inline-block mb-2 mr-4 align-middle leading-none\"],[17,1],[12],[2,\"\\n  \"],[11,\"input\"],[16,\"checked\",[32,0,[\"isChecked\"]]],[24,0,\"absolute opacity-0\"],[16,\"disabled\",[32,2]],[16,3,[32,3]],[16,2,[32,4]],[24,4,\"radio\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"onClick\"]],[32,4]],null]],null],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"hcs-radio-pseudo bg-white cursor-pointer absolute rounded-full block h-5 w-5\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"hcs-label mb-0 cursor-pointer\"],[12],[2,\"\\n    \"],[18,5,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}","meta":{"moduleName":"ember-atoms/components/radio.hbs"}});

  /**
    `Radio` buttons are used when a list of two or more options are mutually exclusive,
    meaning the user must select only one option.
  
    ```js
      <Radio
        @group="someGroup"
        @groupValue={{this.groupValue}}
        @isDisabled={{this.isDisabled}}
        @value="someValue"
        @onClick={{this.changeValue}}
      >
        Banana
      </Radio>
    ```
  
    @class Radio
   */
  let Radio = (_dec = Ember._action, (_class = class Radio extends _component.default {
    get isChecked() {
      return this.args.groupValue === this.args.value;
    }

    onClick(value, event) {
      if (value !== this.args.groupValue) {
        this.args.onClick(value, event);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = Radio;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Radio);
});