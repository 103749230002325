define("ember-atoms/components/toggle", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label class="hcs-toggle h-6 mb-2 mr-4 align-middle inline-flex" ...attributes>
    <input
      checked={{@isChecked}}
      class="absolute opacity-0"
      disabled={{@isDisabled}}
      type="checkbox"
      {{on "click" this.onClick}}
    >
    <span class="faux-toggle active:shadow-none" />
    <span class="label-off hcs-label order-first mr-3 cursor-pointer">
      {{@offLabel}}
    </span>
    <span class="label-on hcs-label ml-3 cursor-pointer">
      {{@onLabel}}
    </span>
  </label>
  
  */
  {"id":"0eVj01ZO","block":"{\"symbols\":[\"&attrs\",\"@isChecked\",\"@isDisabled\",\"@offLabel\",\"@onLabel\"],\"statements\":[[11,\"label\"],[24,0,\"hcs-toggle h-6 mb-2 mr-4 align-middle inline-flex\"],[17,1],[12],[2,\"\\n  \"],[11,\"input\"],[16,\"checked\",[32,2]],[24,0,\"absolute opacity-0\"],[16,\"disabled\",[32,3]],[24,4,\"checkbox\"],[4,[38,0],[\"click\",[32,0,[\"onClick\"]]],null],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"faux-toggle active:shadow-none\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"label-off hcs-label order-first mr-3 cursor-pointer\"],[12],[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"label-on hcs-label ml-3 cursor-pointer\"],[12],[2,\"\\n    \"],[1,[32,5]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}","meta":{"moduleName":"ember-atoms/components/toggle.hbs"}});

  /**
    `Toggle` is a fancy checkbox.
    It's a control that is used to quickly switch between two possible states.
  
    ```js
      <Toggle
        @isChecked={{this.isChecked}}
        @isDisabled={{this.isDisabled}}
        @offLabel="No"
        @onLabel="Yes"
        @onClick={{this.toggleSomething}}
      />
    ```
  
    @class Toggle
   */
  let Toggle = (_dec = Ember._action, (_class = class Toggle extends _component.default {
    onClick(event) {
      event.stopPropagation();
      let {
        checked
      } = event.target;
      this.args.onClick(checked, event);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = Toggle;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Toggle);
});