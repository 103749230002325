define("ember-keyboard/mixins/activate-keyboard-on-init", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init(...args) {
      (false && !(false) && Ember.deprecate('`EKOnInitMixin` mixin of ember-keyboard is deprecated. This behavior is now the default when using the @keyResponder decorator.', false, {
        id: 'ember-keyboard.activate-keyboard-on-init-mixin',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#activate-keyboard-on-init-mixin'
      }));
      return this._super(...args);
    },

    activateKeyboardWhenStarted: Ember.on('init', function () {
      Ember.set(this, 'keyboardActivated', true);
    })
  });

  _exports.default = _default;
});