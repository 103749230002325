define("x-filters/models/filters/base", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    type: 'filters/base',
    title: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    readOnly: (0, _model.attr)('boolean'),
    field: (0, _model.attr)('string'),
    hidden: (0, _model.attr)('boolean'),
    dirty: false,
    searchImmediately: false,

    toggle(nowChecked) {
      this.set('active', nowChecked);
    },

    serialize() {
      if (this.field) {
        return {
          field: this.field,
          type: this.type
        };
      } else {
        return {
          id: this.id
        };
      }
    },

    modelFromParam(param) {
      this.set('value', param.value);
    }

  });

  _exports.default = _default;
});