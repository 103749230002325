define("v-avatar/templates/components/v-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TVYeIAnN",
    "block": "{\"symbols\":[\"@name\",\"@className\"],\"statements\":[[6,[37,0],[[32,0,[\"url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"avatar-img\",[],[[\"@name\",\"@className\",\"@url\",\"@titlePlacement\"],[[32,1],[32,2],[32,0,[\"url\"]],[32,0,[\"titlePlacement\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"avatar-svg\",[],[[\"@name\",\"@className\",\"@titlePlacement\"],[[32,1],[32,2],[32,0,[\"titlePlacement\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "v-avatar/templates/components/v-avatar.hbs"
    }
  });

  _exports.default = _default;
});