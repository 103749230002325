define("ember-select/components/select-dropdown-option", ["exports", "ember-select/templates/components/select-dropdown-option"], function (_exports, _selectDropdownOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _selectDropdownOption.default,
    classNames: ['es-option'],
    classNameBindings: ['model.isSelected:es-highlight'],

    init() {
      this._super(...arguments);

      this.set('handleMouseEnter', Ember.run.bind(this, () => this.hover(this.model)));
    },

    didInsertElement() {
      this._super(...arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    },

    click() {
      this.select(this.model);
    }

  });

  _exports.default = _default;
});