define("ember-cli-bootstrap-datetimepicker/-private/dynamic-attribute-bindings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    NON_ATTRIBUTE_BOUND_PROPS: ['class', 'classNames'],
    concatenatedProperties: ['NON_ATTRIBUTE_BOUND_PROPS'],

    init() {
      this._super(...arguments);

      let newAttributeBindings = []; // eslint-disable-next-line ember/no-attrs-in-components

      for (let key in this.attrs) {
        if (this.NON_ATTRIBUTE_BOUND_PROPS.indexOf(key) === -1 && this.attributeBindings.indexOf(key) === -1) {
          newAttributeBindings.push(key);
        }
      }

      Ember.set(this, 'attributeBindings', this.attributeBindings.concat(newAttributeBindings));
    }

  });

  _exports.default = _default;
});