define("x-gauge/templates/components/x-gauge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W0rgjz76",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"-1 -1 2 2\"],[14,5,\"transform: rotate(0.25turn);\"],[12],[2,\"\\n  \"],[10,\"circle\"],[14,\"cx\",\"0\"],[14,\"cy\",\"0\"],[14,\"r\",\"1\"],[15,\"fill\",[34,1,[\"bg\"]]],[12],[13],[2,\"\\n  \"],[10,\"path\"],[15,\"d\",[31,[\"M 1 0 A 1 1 0 0 1 \",[34,0,[\"right\",\"x\"]],\" \",[34,0,[\"right\",\"y\"]],\" L 0 0\"]]],[15,\"fill\",[34,1,[\"fg\"]]],[12],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[35,2],50],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"path\"],[15,\"d\",[31,[\"M -1 0 A 1 1 0 0 1 \",[34,0,[\"left\",\"x\"]],\" \",[34,0,[\"left\",\"y\"]],\" L 0 0\"]]],[15,\"fill\",[34,1,[\"fg\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"circle\"],[14,\"cx\",\"0\"],[14,\"cy\",\"0\"],[14,\"r\",\".9\"],[14,\"fill\",\"white\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n  \"],[18,1,[[35,1]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"coords\",\"colors\",\"percent\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "x-gauge/templates/components/x-gauge.hbs"
    }
  });

  _exports.default = _default;
});