define("x-filters/templates/components/filters/combo-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8mSqladk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"x-select\",[],[[\"@model\",\"@value\",\"@placeholder\",\"@valueKey\",\"@labelKey\",\"@onSelect\",\"@canSearch\",\"@openOnFocus\"],[[34,0,[\"possibilities\"]],[34,0,[\"value\"]],\"Select a value to search\",\"id\",\"value\",[30,[36,1],[[32,0],\"selectValue\"],null],false,true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\"]}",
    "meta": {
      "moduleName": "x-filters/templates/components/filters/combo-filter.hbs"
    }
  });

  _exports.default = _default;
});