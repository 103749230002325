define("ember-keyboard/initializers/ember-keyboard-first-responder-inputs", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (application) {
      let config = application.resolveRegistration('config:environment') || {};
      let disableInputsInitializer = Boolean(Ember.get(config, 'emberKeyboard.disableInputsInitializer'));

      if (disableInputsInitializer) {
        return;
      }
    }

    (false && !(false) && Ember.deprecate('The `ember-keyboard-first-responder-inputs` initializer is deprecated and will be removed in 7.0. Please use the `on-key` modifier with your text fields instead.', false, {
      id: 'ember-keyboard.first-responder-inputs',
      until: '7.0.0',
      url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#first-responder-inputs'
    }));
    Ember.TextField.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
    Ember.TextArea.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
  }

  var _default = {
    name: 'ember-keyboard-first-responder-inputs',
    initialize: initialize
  };
  _exports.default = _default;
});