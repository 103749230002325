define("v-avatar/templates/components/avatar-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vx2VLhET",
    "block": "{\"symbols\":[\"@className\",\"@titlePlacement\",\"@name\"],\"statements\":[[10,\"svg\"],[14,\"viewBox\",\"0 0 100 100\"],[15,0,[32,1]],[15,5,[32,0,[\"style\"]]],[14,\"data-toggle\",\"tooltip\"],[15,\"data-placement\",[32,2]],[15,\"data-original-title\",[32,3]],[12],[2,\"\\n  \"],[10,\"text\"],[14,\"y\",\"50%\"],[14,\"x\",\"50%\"],[14,\"dy\",\"0.35em\"],[14,\"text-anchor\",\"middle\"],[14,\"fill\",\"White\"],[14,\"font-size\",\"50\"],[12],[1,[32,0,[\"initials\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "v-avatar/templates/components/avatar-svg.hbs"
    }
  });

  _exports.default = _default;
});