define("ember-select/templates/components/select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C6rg+Ltr",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"model\",\"select\",\"hover\"],[[32,1],[30,[36,0],[[32,0],\"select\"],null],[30,[36,0],[[32,0],\"hover\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"select-dropdown-option\",\"if\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-select/templates/components/select-dropdown.hbs"
    }
  });

  _exports.default = _default;
});