define("ember-select/templates/components/select-dropdown-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UyF7ECrS",
    "block": "{\"symbols\":[\"group\",\"option\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"es-group\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1,[\"children\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,2,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"model\",\"select\",\"hover\"],[[32,2],[30,[36,0],[[32,0],\"select\"],null],[30,[36,0],[[32,0],\"hover\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"select-dropdown-option\",\"if\",\"-track-array\",\"each\",\"options\"]}",
    "meta": {
      "moduleName": "ember-select/templates/components/select-dropdown-group.hbs"
    }
  });

  _exports.default = _default;
});