define("ember-keyboard/helpers/on-key", ["exports", "ember-keyboard/utils/listener-name"], function (_exports, _listenerName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec = Ember.inject.service, (_class2 = (_temp = class _class2 extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "keyboard", _descriptor, this);

      _defineProperty(this, "keyCombo", void 0);

      _defineProperty(this, "callback", void 0);

      _defineProperty(this, "keyboardActivated", true);

      _defineProperty(this, "keyboardPriority", 0);

      _defineProperty(this, "eventName", 'keydown');

      _defineProperty(this, "keyboardHandlers", void 0);
    }

    compute([keyCombo, callback], {
      event = 'keydown',
      activated = true,
      priority = 0
    }) {
      (false && !(typeof callback === 'function') && Ember.assert('ember-keyboard: You must pass a function as the second argument to the `on-key` helper', typeof callback === 'function'));
      this.keyCombo = keyCombo;
      this.callback = callback;
      this.eventName = event;
      this.keyboardActivated = activated;
      this.keyboardPriority = priority;
      this.keyboardHandlers = {};
      this.keyboardHandlers[(0, _listenerName.default)(event, keyCombo)] = callback;
      this.keyboard.register(this);
    }

    destroy() {
      this.keyboard.unregister(this);
      super.destroy(...arguments);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "keyboard", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});