define("x-pdf/utils/pdfjs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pdfJs;
  // Some logic is contained in a separate file upstream. Download both sources and concatenate:
  // https://unpkg.com/pdfjs-dist@2.6.347/es5/build/pdf.js
  // https://unpkg.com/pdfjs-dist@2.6.347/es5/web/pdf_viewer.js
  //   npx google-closure-compiler --language_out=ECMASCRIPT5 --js=pdf.js --js=pdf_viewer.js --js_output_file=pdf.min.js
  const PDFJS_PATH = '//cdn.healthcaresource.com/assets/pdfjs/2.6.347';

  function pdfJs() {
    return new Ember.RSVP.Promise(resolve => {
      if (window.pdfjsLib) {
        return resolve(window.pdfjsLib);
      }

      return resolve(loadPdfJs());
    });
  }

  function loadPdfJs() {
    return new Ember.RSVP.Promise(resolve => {
      let js = document.createElement('script');
      js.type = 'text/javascript';
      js.src = "".concat(PDFJS_PATH, "/pdf.min.js");

      js.onload = () => {
        let {
          pdfjsLib
        } = window;
        pdfjsLib.GlobalWorkerOptions.workerSrc = "".concat(PDFJS_PATH, "/pdf.worker.min.js");
        pdfjsLib.HCSWorker = new pdfjsLib.PDFWorker();
        resolve(pdfjsLib);
      };

      document.getElementsByTagName('head').item(0).appendChild(js);
    });
  }
});