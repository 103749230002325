define("ember-keyboard/deprecated/modifiers/on-keyboard", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Klass;

  if (true) {
    var _dec, _dec2, _dec3, _class, _descriptor, _temp;

    /* This is an element modifier to trigger a click on the element when
     * the specified key is pressed. In the following example, we trigger a
     * click on the button when the B key is pressed:
     *
     * <input
     *    type="text"
     *    placeholder="Type and then press enter to save"
     *    {{on-keyboard 'Enter' (action this.saveChanges)}
     * >
     */
    Klass = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class OnKeyboardModifier extends _emberModifier.default {
      constructor(...args) {
        super(...args);

        _initializerDefineProperty(this, "keyboard", _descriptor, this);

        _defineProperty(this, "keyboardActivated", true);

        _defineProperty(this, "keyboardPriority", 0);

        _defineProperty(this, "keyboardFirstResponder", false);

        _defineProperty(this, "keyboardEventType", 'keydown');

        _defineProperty(this, "onlyWhenFocused", true);
      }

      didReceiveArguments() {
        this.key = this.args.positional[0];
        this.keyboardAction = this.args.positional[1];

        if (this.args.named.onlyWhenFocused !== undefined) {
          this.onlyWhenFocused = this.args.named.onlyWhenFocused;
        } // Future TODO: support specifying keyboardEventType, keyboardActivated,
        // keyboardPriority, and keyboardFirstResponder via named arguments.
        // This should be straightforward, just needs test coverage and some API
        // decisions.

      }

      didInstall() {
        (false && !(false) && Ember.deprecate('The `on-keyboard` modifier of ember-keyboard is deprecated. Please use the `on-key` modifier instead.', false, {
          id: 'ember-keyboard.on-keyboard',
          until: '7.0.0',
          url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#on-keyboard'
        }));
        this.keyboard.register(this);

        if (this.onlyWhenFocused) {
          this.keyboardActivated = false;
          this.keyboardFirstResponder = false;
          this.element.addEventListener('click', this.onFocus, true);
          this.element.addEventListener('focus', this.onFocus, true);
          this.element.addEventListener('focusout', this.onFocusOut, true);
        }
      }

      willRemove() {
        if (this.onlyWhenFocused) {
          this.element.removeEventListener('click', this.onFocus, true);
          this.element.removeEventListener('focus', this.onFocus, true);
          this.element.removeEventListener('focusout', this.onFocusOut, true);
          this.keyboard.unregister(this);
        }
      }

      onFocus() {
        this.keyboardActivated = true;
        this.keyboardFirstResponder = true;
      }

      onFocusOut() {
        this.keyboardActivated = false;
        this.keyboardFirstResponder = false;
      }

      has(triggerName) {
        return triggerName === this.keyboardEventName;
      }

      trigger(listenerName) {
        if (listenerName === this.keyboardEventName) {
          this.keyboardAction();
        }
      }

      get keyboardEventName() {
        let {
          key,
          keyboardEventType
        } = this;
        return "".concat(keyboardEventType, ":").concat(key);
      }

    }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keyboard", [_dec], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class.prototype, "onFocus", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocusOut", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusOut"), _class.prototype)), _class));
  } else {
    Klass = class OnKeyboardModifier extends _emberModifier.default {
      didInstall() {
        throw new Error('ember-keyboard only supports the on-keyboard element modifier in Ember 3.8 and higher.');
      }

    };
  }

  var _default = Klass;
  _exports.default = _default;
});