define("x-page/components/x-text-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.isVisible}}
    <div class="form-group {{if @model.error "has-error"}}">
      <TextArea
        @class="form-control"
        @isDisabled={{@readOnly}}
        @maxLength={{@model.maxLength}}
        @maxRows={{@model.rows}}
        @rows={{2}}
        @value={{@model.value}}
        @onInput={{action (mut @model.value)}}
      >
        {{!-- Allow triple-curlies for html content from api --}}
        {{!-- template-lint-disable triple-curlies --}}
        {{{@model.label}}}
      </TextArea>
  
      <FieldError @model={{@model.error}} />
    </div>
  {{/if}}
  
  */
  {
    "id": "7jwlyAGN",
    "block": "{\"symbols\":[\"@model\",\"@readOnly\"],\"statements\":[[6,[37,0],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,0],[[32,1,[\"error\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[8,\"text-area\",[],[[\"@class\",\"@isDisabled\",\"@maxLength\",\"@maxRows\",\"@rows\",\"@value\",\"@onInput\"],[\"form-control\",[32,2],[32,1,[\"maxLength\"]],[32,1,[\"rows\"]],2,[32,1,[\"value\"]],[30,[36,2],[[32,0],[30,[36,1],[[32,1,[\"value\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\"],[2,\"      \"],[2,[32,1,[\"label\"]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"field-error\",[],[[\"@model\"],[[32,1,[\"error\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "x-page/components/x-text-area.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});