define("x-filters/components/x-filter", ["exports", "x-filters/templates/components/x-filter"], function (_exports, _xFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['model.active:active'],
    classNames: ['filter'],
    layout: _xFilter.default,
    componentName: '',

    init() {
      this._super(...arguments);

      this.set('componentName', this.model.constructor.modelName);
    },

    actions: {
      updateFilters() {
        this.updateFilters();
      },

      filterToggle(model, nowChecked) {
        model.toggle(nowChecked);
        this.toggle(model, nowChecked);
      }

    }
  });

  _exports.default = _default;
});