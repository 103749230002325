define("x-page/components/x-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.isVisible}}
    <div class="form-group {{if @model.error "has-error"}}">
      <TextInput
        @autocomplete={{false}}
        @isDisabled={{@readOnly}}
        @mask={{@model.inputMask}}
        @maxLength={{@model.maxLength}}
        @type={{this.getType}}
        @value={{@model.value}}
        @onInput={{this.onInput}}
      >
        {{!-- Allow triple-curlies for html content from api --}}
        {{!-- template-lint-disable triple-curlies --}}
        {{{@model.label}}}
      </TextInput>
  
      <FieldError @model={{@model.error}} />
    </div>
  {{/if}}
  */
  {
    "id": "PoOZvfg4",
    "block": "{\"symbols\":[\"@model\",\"@readOnly\"],\"statements\":[[6,[37,0],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,0],[[32,1,[\"error\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[8,\"text-input\",[],[[\"@autocomplete\",\"@isDisabled\",\"@mask\",\"@maxLength\",\"@type\",\"@value\",\"@onInput\"],[false,[32,2],[32,1,[\"inputMask\"]],[32,1,[\"maxLength\"]],[32,0,[\"getType\"]],[32,1,[\"value\"]],[32,0,[\"onInput\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"      \"],[2,[32,1,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"field-error\",[],[[\"@model\"],[[32,1,[\"error\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "x-page/components/x-input.hbs"
    }
  });

  const MOBILE = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|kindle|silk/i;
  let PageTextInputComponent = (_dec = Ember._action, (_class = class PageTextInputComponent extends _component.default {
    get getType() {
      if (this.args.model.inputMask === 'decimal' || this.args.model.inputMask === 'number') {
        return MOBILE.test(navigator.userAgent) ? 'decimal' : 'tel';
      } else {
        return 'text';
      }
    }

    onInput(value) {
      this.args.model.value = value;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onInput", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class));
  _exports.default = PageTextInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PageTextInputComponent);
});