define("x-filters/components/filters/text-filter", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="row">
    <span class="col-xs-12">
      <TextInput
        @autocomplete={{false}}
        @mask={{@model.inputMask}}
        @maxLength={{@model.maxLength}}
        @placeholder={{@model.placeholder}}
        @value={{@model.value}}
        @onInput={{this.onInput}}
      />
    </span>
  </div>
  
  {{#if (and @model.dirty this.isMaskValid)}}
    <span class="btn btn-default col-xs-12 top10" {{on "click" @updateFilters}}>
      Update
    </span>
  {{/if}}
  
  */
  {"id":"dcRjxqZ/","block":"{\"symbols\":[\"@updateFilters\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n    \"],[8,\"text-input\",[],[[\"@autocomplete\",\"@mask\",\"@maxLength\",\"@placeholder\",\"@value\",\"@onInput\"],[false,[32,2,[\"inputMask\"]],[32,2,[\"maxLength\"]],[32,2,[\"placeholder\"]],[32,2,[\"value\"]],[32,0,[\"onInput\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,2,[\"dirty\"]],[32,0,[\"isMaskValid\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"span\"],[24,0,\"btn btn-default col-xs-12 top10\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    Update\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"and\",\"if\"]}","meta":{"moduleName":"x-filters/components/filters/text-filter.hbs"}});

  let TextFilterComponent = (_dec = Ember._action, (_class = class TextFilterComponent extends _component.default {
    get isMaskValid() {
      return this.args.model.inputMask ? this.args.model.value.length === this.args.model.inputMask.length : true;
    }

    onInput(text) {
      this.args.model.setProperties({
        dirty: true,
        value: text
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onInput", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class));
  _exports.default = TextFilterComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TextFilterComponent);
});