define("v-stars/components/v-stars", ["exports", "v-stars/templates/components/v-stars"], function (_exports, _vStars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _vStars.default,
    classNames: ['ec-stars'],
    classNameBindings: ['hover:pointer'],
    hover: null,
    rate: Ember.computed.or('hover', 'rating'),
    width: Ember.computed('rate', function () {
      let rating = this.rate || 0;
      return Ember.String.htmlSafe("width: ".concat(rating, "%;"));
    }),
    color: Ember.computed('rate', function () {
      let rating = this.rate || 0;

      if (rating <= 37.5) {
        return 'red';
      }

      if (rating <= 62.5) {
        return 'gold';
      }

      return 'blue';
    }),

    didInsertElement() {
      this._super(...arguments);

      this.element.addEventListener('mouseleave', Ember.run.bind(this, this.handleMouseLeave));
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseleave', Ember.run.bind(this, this.handleMouseLeave));
    },

    actions: {
      hover(percent) {
        // Only update hover if rating
        if (this.onRate) {
          this.set('hover', percent); // Notify parent of change

          if (this.onHover) {
            this.onHover(percent);
          }
        }
      }

    },

    handleMouseLeave() {
      this.send('hover', null);
    },

    click() {
      if (this.hover) {
        this.onRate(this.hover);
      }
    }

  });

  _exports.default = _default;
});