define("x-page/components/x-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.isVisible}}
    <h2>
      {{!-- Allow triple-curlies for html content from api --}}
      {{!-- template-lint-disable triple-curlies --}}
      {{{@model.label}}}
      <hr class="no-margin hr-title">
    </h2>
  {{/if}}
  
  */
  {
    "id": "rd0pXne2",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,0],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h2\"],[12],[2,\"\\n\"],[2,\"    \"],[2,[32,1,[\"label\"]]],[2,\"\\n    \"],[10,\"hr\"],[14,0,\"no-margin hr-title\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "x-page/components/x-title.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});