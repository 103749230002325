define("x-check/components/x-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['checked', 'type', 'value', 'disabled'],
    checked: false,
    classNames: ['pointer'],
    tagName: 'input',
    type: 'checkbox',

    didInsertElement() {
      this._super(...arguments);

      let checked = this.get('checked');

      if (checked === null) {
        this.element.indeterminate = true;
      }
    },

    click(event) {
      /*
        Checkbox may be wrapped inside a `div` with similar
        action for a larger click area. Don't trigger twice.
      */
      event.stopPropagation();
      let {
        checked
      } = event.target;
      this.attrs.action(this.get('value'), checked);
    }

  });

  _exports.default = _default;
});