define("x-filters/templates/components/x-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OTcHed3+",
    "block": "{\"symbols\":[\"filter\"],\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[32,1,[\"hidden\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"x-filter\",[],[[\"@class\",\"@type\",\"@model\",\"@eventList\",\"@forceRequisitions\",\"@toggle\",\"@originOrgId\",\"@updateFilters\"],[\"col-xs-12 top10\",[34,1],[32,1],[34,2],[34,3],[30,[36,0],[[32,0],\"filterToggle\"],null],[34,4],[30,[36,0],[[32,0],\"updateFilters\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"ro-overlay\"],[4,[38,0],[[32,0],\"ignore\"],[[\"bubbles\"],[false]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"type\",\"eventList\",\"forceRequisitions\",\"originOrgId\",\"unless\",\"filterObjects\",\"-track-array\",\"each\",\"readonly\",\"if\"]}",
    "meta": {
      "moduleName": "x-filters/templates/components/x-filters.hbs"
    }
  });

  _exports.default = _default;
});