define("x-filters/components/filters/combo-filter", ["exports", "x-filters/templates/components/filters/combo-filter"], function (_exports, _comboFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _comboFilter.default,
    actions: {
      selectValue(id) {
        this.set('model.value', id);
        this.updateFilters();
      }

    }
  });

  _exports.default = _default;
});