define("ember-atoms/tailwind/config", [], function () {
  "use strict";

  /* global module */
  module.exports = {
    theme: {
      fontFamily: {
        display: ['Barlow', 'sans-serif'],
        body: ['Roboto', 'sans-serif']
      },
      colors: {
        'yellow': {
          200: '#FFF4C1',
          400: '#FFE150',
          500: '#FFD405',
          600: '#E6BF05',
          800: '#4D4002'
        },
        'red': {
          200: '#FFE2DC',
          400: '#EB6A4D',
          500: '#E32A00',
          600: '#CC2600',
          800: '#440D00'
        },
        'green': {
          200: '#DEEDBF',
          400: '#A2CD4D',
          500: '#7AB800',
          600: '#6EA600',
          800: '#253700'
        },
        'blue': {
          100: '#E6F6FD',
          200: '#BFEAF9',
          300: '#99DDF5',
          400: '#4DC3EE',
          500: '#00A9E7',
          600: '#0098D0',
          700: '#007CC2',
          800: '#004585',
          900: '#002E58'
        },
        'gray': {
          100: '#F7FAFC',
          200: '#EDF2F7',
          300: '#E2E8F0',
          400: '#CBD5E0',
          500: '#A0AEC0',
          600: '#718096',
          700: '#4A5568',
          800: '#2D3748',
          900: '#1A202C'
        },
        'white': '#fff',
        'black': '#000',
        'transparent': 'transparent'
      },
      extend: {
        borderWidth: {
          '3': '3px'
        }
      }
    },
    variants: {},
    plugins: []
  };
});