define("x-page/utils/scrollTo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollTo;
  const {
    $
  } = window;

  function scrollTo(el, ...options) {
    let scrollTop = $(el).offset().top;
    $('html, body').animate({
      scrollTop
    }, ...options);
  }
});