define("x-page/components/x-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.isVisible}}
    <div class={{if @model.error "has-error"}}>
      {{!-- Allow triple-curlies for hardcoded html --}}
      {{!-- template-lint-disable triple-curlies --}}
      {{{@model.label}}}
  
      <FieldError @model={{@model.error}} />
    </div>
  {{/if}}
  
  */
  {
    "id": "DfyIl+Sm",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,0],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[30,[36,0],[[32,1,[\"error\"]],\"has-error\"],null]],[12],[2,\"\\n\"],[2,\"    \"],[2,[32,1,[\"label\"]]],[2,\"\\n\\n    \"],[8,\"field-error\",[],[[\"@model\"],[[32,1,[\"error\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "x-page/components/x-label.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});