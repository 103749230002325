define("x-filters/components/filters/number-compare-filter", ["exports", "x-filters/templates/components/filters/number-compare-filter"], function (_exports, _numberCompareFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _numberCompareFilter.default,
    operatorList: [{
      name: 'Less than',
      id: 1
    }, {
      name: 'Equal to',
      id: 0
    }, {
      name: 'Greater than',
      id: 3
    }],
    actions: {
      commitChanges() {
        this.set('model.value', parseInt(this.get('model.value')));
        this.updateFilters();
      },

      updateOperator(op) {
        this.set('model.operator', op);
        this.send('commitChanges');
      }

    }
  });

  _exports.default = _default;
});