define("v-share-job/components/x-text-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    readonly: 'readonly',

    didInsertElement() {
      this._super(...arguments); // Modals have transition


      Ember.run.later(this, () => {
        this.element.focus();
        this.element.select();
      }, 500);
    },

    click() {
      this.element.select();
    },

    mouseUp() {
      return false;
    }

  });

  _exports.default = _default;
});