define("ember-keyboard/mixins/keyboard-first-responder-on-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init(...args) {
      (false && !(false) && Ember.deprecate('`EKFirstResponderOnFocusMixin` of ember-keyboard is deprecated. This behavior is now the default when using the `on-key` modifier with a text field. For other use cases, implement manually.', false, {
        id: 'ember-keyboard.keyboard-first-responder-on-focus-mixin',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#keyboard-first-responder-on-focus-mixin'
      }));
      return this._super(...args);
    },

    makeFirstResponderOnFocusIn: Ember.on('click', 'focusIn', function () {
      Ember.setProperties(this, {
        keyboardActivated: true,
        keyboardFirstResponder: true
      });
    }),
    resignFirstResponderOnFocusOut: Ember.on('focusOut', function () {
      Ember.set(this, 'keyboardFirstResponder', false);
    })
  });

  _exports.default = _default;
});