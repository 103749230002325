define("x-button/templates/components/x-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9gxfma8q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-refresh fa-spin fa-fw\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "x-button/templates/components/x-button.hbs"
    }
  });

  _exports.default = _default;
});