define("ember-keyboard/decorators/key-responder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = keyResponder;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function populateKeyboardHandlers(responder) {
    responder.keyboardHandlers = responder.keyboardHandlers || {};

    if (!responder.keyboardHandlerNames) {
      responder.keyboardHandlerNames = {};

      for (let propertyName in responder) {
        let propertyValue = responder[propertyName];

        if (typeof propertyValue === 'function' && propertyValue._emberKeyboardOnKeyDecoratorData) {
          for (let listenerName of propertyValue._emberKeyboardOnKeyDecoratorData.listenerNames || []) {
            responder.keyboardHandlerNames[listenerName] = propertyName;
          }
        }
      }
    }

    for (let [listenerName, methodName] of Object.entries(responder.keyboardHandlerNames || {})) {
      responder.keyboardHandlers[listenerName] = responder[methodName].bind(responder);
    }
  }

  function keyResponder(DecoratedClass, opts = {}) {
    var _dec, _class, _descriptor, _class2, _temp;

    if (opts.priority === undefined) {
      opts.priority = 0;
    }

    if (opts.activated === undefined) {
      opts.activated = true;
    }

    return _dec = Ember.inject.service, (_class = (_temp = _class2 = class ClassAsKeyResponder extends DecoratedClass {
      get keyboardPriority() {
        if (super.keyboardPriority === undefined) {
          return opts.priority;
        }

        return super.keyboardPriority;
      }

      set keyboardPriority(val) {
        super.keyboardPriority = val;
      }

      get keyboardActivated() {
        if (super.keyboardActivated === undefined) {
          return opts.activated;
        }

        return super.keyboardActivated;
      }

      set keyboardActivated(val) {
        super.keyboardActivated = val;
      }

      constructor() {
        super(...arguments);

        _initializerDefineProperty(this, "keyboard", _descriptor, this);

        populateKeyboardHandlers(this);
        this.keyboard.register(this);
      }

      willDestroy() {
        this.keyboard.unregister(this);
        super.willDestroy(...arguments);
      }

    }, _defineProperty(_class2, "name", "".concat(DecoratedClass.name, "WithKeyResponder")), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keyboard", [_dec], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    })), _class);
  }
});