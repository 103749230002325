define("v-share-job/templates/components/direct-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aFIU39T6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"x-modal\",[],[[\"@title\",\"@className\",\"@aLabel\",\"@aClass\",\"@aAction\",\"@aDisabled\",\"@bLabel\",\"@bClass\",\"@bAction\"],[[34,0],\"modal-lg\",\"Copy To Clipboard\",\"btn-primary\",[30,[36,1],[[32,0],\"copy\"],null],[30,[36,3],[[35,2]],null],\"Close\",\"btn-default\",[30,[36,1],[[32,0],[35,4]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\\n  \"],[8,\"x-text-select\",[],[[\"@id\",\"@class\",\"@value\"],[\"direct-link\",\"col-xs-12\",[34,5]]],null],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"action\",\"canCopy\",\"not\",\"close\",\"link\"]}",
    "meta": {
      "moduleName": "v-share-job/templates/components/direct-link.hbs"
    }
  });

  _exports.default = _default;
});