define("x-page/components/x-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.isVisible}}
    <div class="form-group radio {{if @model.error "has-error"}}">
      <Radio
        @group={{@model.field}}
        @groupValue={{@model.value}}
        @isDisabled={{@readOnly}}
        @value={{@model.radioValue}}
        @onClick={{action (mut @model.value)}}
      >
        {{@model.label}}
      </Radio>
    </div>
  {{/if}}
  
  */
  {
    "id": "I2oY8H90",
    "block": "{\"symbols\":[\"@model\",\"@readOnly\"],\"statements\":[[6,[37,0],[[32,1,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"form-group radio \",[30,[36,0],[[32,1,[\"error\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[8,\"radio\",[],[[\"@group\",\"@groupValue\",\"@isDisabled\",\"@value\",\"@onClick\"],[[32,1,[\"field\"]],[32,1,[\"value\"]],[32,2],[32,1,[\"radioValue\"]],[30,[36,2],[[32,0],[30,[36,1],[[32,1,[\"value\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "x-page/components/x-radio.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});