define("ember-atoms/components/text-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _temp;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="hcs-text" ...attributes>
    <label class="hcs-label">
      <span class="block">
        {{yield}}
      </span>
  
      <input
        autocomplete={{if @autocomplete "on" "rejected"}}
        autofocus={{@autofocus}}
        class="hcs-text-input"
        disabled={{@isDisabled}}
        max={{this.max}}
        maxlength={{@maxLength}}
        min={{@min}}
        placeholder={{@placeholder}}
        readonly={{@isReadonly}}
        value={{this.getValue}}
        type={{@type}}
        {{on "blur" (fn this.onFocusOut)}}
        {{on "focus" (fn this.onFocusIn)}}
        {{on "input" (fn this.onInput)}}
        {{on "keydown" (fn this.onKeyDown)}}
        {{did-insert (fn this.autofocus)}}
      >
    </label>
  </div>
  
  */
  {"id":"bcdQ7Irn","block":"{\"symbols\":[\"&attrs\",\"&default\",\"@autocomplete\",\"@autofocus\",\"@isDisabled\",\"@maxLength\",\"@min\",\"@placeholder\",\"@isReadonly\",\"@type\"],\"statements\":[[11,\"div\"],[24,0,\"hcs-text\"],[17,1],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"hcs-label\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"block\"],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"input\"],[16,\"autocomplete\",[30,[36,0],[[32,3],\"on\",\"rejected\"],null]],[16,\"autofocus\",[32,4]],[24,0,\"hcs-text-input\"],[16,\"disabled\",[32,5]],[16,\"max\",[32,0,[\"max\"]]],[16,\"maxlength\",[32,6]],[16,\"min\",[32,7]],[16,\"placeholder\",[32,8]],[16,\"readonly\",[32,9]],[16,2,[32,0,[\"getValue\"]]],[16,4,[32,10]],[4,[38,2],[\"blur\",[30,[36,1],[[32,0,[\"onFocusOut\"]]],null]],null],[4,[38,2],[\"focus\",[30,[36,1],[[32,0,[\"onFocusIn\"]]],null]],null],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"onInput\"]]],null]],null],[4,[38,2],[\"keydown\",[30,[36,1],[[32,0,[\"onKeyDown\"]]],null]],null],[4,[38,3],[[30,[36,1],[[32,0,[\"autofocus\"]]],null]],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\",\"did-insert\"]}","meta":{"moduleName":"ember-atoms/components/text-input.hbs"}});

  /**
    `TextInput` enables the user to interact with and input content and data.
  
    ```js
      <TextInput
        @autofocus={{true}}
        @isDisabled={{this.isDisabled}}
        @mask="(200) 000-0000"
        @placeholder="Phone"
        @value={{this.phone}}
        @onInput={{this.onInput}}
      >
        Phone
      </TextInput>
    ```
  
    @class TextInput
   */
  let TextInput = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class TextInput extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "isDirty", false);

      _defineProperty(this, "mask", void 0);

      if (args.mask) {
        this.mask = new Mask(this.max);
      }
    }

    get max() {
      if (this.args.mask === 'decimal' || this.args.mask === 'number') {
        var _Number$MAX_SAFE_INTE, _this$args$max;

        // IE does not support `Number.MAX_SAFE_INTEGER`
        let maxInt = (_Number$MAX_SAFE_INTE = Number.MAX_SAFE_INTEGER) !== null && _Number$MAX_SAFE_INTE !== void 0 ? _Number$MAX_SAFE_INTE : Math.pow(2, 53) - 1;
        return Math.min((_this$args$max = this.args.max) !== null && _this$args$max !== void 0 ? _this$args$max : maxInt, maxInt);
      }

      return this.args.max;
    }

    get getValue() {
      if (!this.isDirty && this.args.mask === 'decimal' && Number.isFinite(this.args.value) // makes sure value is not a string
      && Number(this.args.value).toFixed(1) === this.args.value.toString()) {
        return Number(this.args.value).toFixed(2);
      }

      return this.args.value;
    }

    autofocus(element) {
      if (this.args.autofocus) {
        element.focus();
      }
    }

    onFocusIn(event) {
      let {
        onFocusIn
      } = this.args;

      if (onFocusIn) {
        onFocusIn(event);
      }
    }

    onFocusOut(event) {
      let {
        onFocusOut
      } = this.args;

      if (onFocusOut) {
        onFocusOut(event);
      }
    }

    onInput(event) {
      this.isDirty = true;

      if (this.mask) {
        let value = this.mask.handleInput(event, this.args.mask);

        if (value !== undefined && value !== this.args.value) {
          this.args.onInput(value, event);
        }
      } else {
        let {
          value
        } = event.target;
        this.args.onInput(value, event);
      }
    }

    onKeyDown(event) {
      this.isDirty = true;

      if (this.mask) {
        this.mask.handleKeydown(event);
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "autofocus", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "autofocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocusIn", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFocusOut", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype)), _class));
  _exports.default = TextInput;

  class Mask {
    // IE does not support `inputType`
    constructor(max) {
      _defineProperty(this, "ieKey", void 0);

      _defineProperty(this, "max", void 0);

      _defineProperty(this, "previousCursor", void 0);

      _defineProperty(this, "previousValue", '');

      this.max = max;
    }

    handleInput(event, mask) {
      let element = event.target;

      if (element.value === '') {
        return '';
      } else if (mask === 'decimal') {
        return this.processRegexNumber(element, /^\d+\.?\d*$/);
      } else if (mask === 'number') {
        return this.processRegexNumber(element, /^\d+$/);
      }

      let cursor = this.previousCursor;
      let rawValue = element.value.replace(/\D/g, '');
      let value = this.mask(rawValue, mask);

      if (value !== this.previousValue && element.value.length <= mask.length) {
        if (event.inputType === 'deleteContentBackward' || this.ieKey === 'Backspace') {
          cursor = Math.max(cursor - 1, 0);
        } else if (event.inputType !== 'deleteContentForward' && this.ieKey !== 'Del') {
          if (this.previousCursor === this.previousValue.length) {
            cursor += Math.max(value.length - this.previousValue.length, 1);
          } else {
            cursor++; // Account for typing not at the end

            for (let i = cursor - 1; i < this.previousValue.length; i++) {
              if (this.previousValue.charAt(i) !== value.charAt(i)) {
                break;
              }

              cursor++;
            }
          }
        }

        element.value = value;
      } else {
        element.value = this.previousValue;
      }

      element.setSelectionRange(cursor, cursor);
      return value;
    }

    handleKeydown(event) {
      let {
        selectionStart,
        value
      } = event.target;
      this.previousCursor = selectionStart !== null && selectionStart !== void 0 ? selectionStart : 0;
      this.previousValue = value;
      this.ieKey = event.key;
    }

    mask(value, mask) {
      let output = '';

      for (let i = 0, j = 0; i < mask.length && j < value.length; i++) {
        let maskChar = mask.charAt(i);

        if (maskChar >= '0' && maskChar <= '9') {
          let valueChar = value.charAt(j);

          if (Number(maskChar) > Number(valueChar)) {
            return output;
          }

          output += valueChar;
          j++;
        } else {
          output += maskChar;
        }
      }

      return output;
    }

    processRegexNumber(element, regex) {
      if (regex.test(element.value)) {
        let value = Number(element.value);

        if (this.max >= value) {
          return value;
        }
      }

      element.value = this.previousValue;
      return;
    }

  }

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TextInput);
});