define("x-filters/components/filters/date-range-filter", ["exports", "@glimmer/component", "date-fns"], function (_exports, _component, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group">
    <label>From</label>
    <BsDatetimepicker
      @date={{this.from}}
      @format={{@model.format}}
      @openOnFocus={{true}}
      @maxDate={{this.to}}
      @change={{this.commitChanges}}
    />
  </div>
  <div class="form-group">
    <label>To</label>
    <BsDatetimepicker
      @date={{this.to}}
      @format={{@model.format}}
      @openOnFocus={{true}}
      @showTodayButton={{true}}
      @minDate={{this.from}}
      @maxDate={{this.maxDate}}
      @change={{this.commitChanges}}
    />
  </div>
  
  */
  {"id":"fxOi4S9O","block":"{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"From\"],[13],[2,\"\\n  \"],[8,\"bs-datetimepicker\",[],[[\"@date\",\"@format\",\"@openOnFocus\",\"@maxDate\",\"@change\"],[[32,0,[\"from\"]],[32,1,[\"format\"]],true,[32,0,[\"to\"]],[32,0,[\"commitChanges\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"To\"],[13],[2,\"\\n  \"],[8,\"bs-datetimepicker\",[],[[\"@date\",\"@format\",\"@openOnFocus\",\"@showTodayButton\",\"@minDate\",\"@maxDate\",\"@change\"],[[32,0,[\"to\"]],[32,1,[\"format\"]],true,true,[32,0,[\"from\"]],[32,0,[\"maxDate\"]],[32,0,[\"commitChanges\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"x-filters/components/filters/date-range-filter.hbs"}});

  let DateRangeFilterComponent = (_dec = Ember.computed('args.model.value.from'), _dec2 = Ember.computed('args.model.value.to'), _dec3 = Ember._action, (_class = (_temp = class DateRangeFilterComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "fromValue", this.args.model.value.from);

      _defineProperty(this, "toValue", this.args.model.value.to);

      _defineProperty(this, "maxDate", this.args.model.allowFuture ? false : (0, _dateFns.endOfToday)());
    }

    get from() {
      return (0, _dateFns.addDays)((0, _dateFns.startOfToday)(), this.args.model.value.from);
    }

    set from(value) {
      if (value === null) {
        return;
      }

      Ember.set(this.args.model.value, 'from', (0, _dateFns.differenceInDays)(value, (0, _dateFns.startOfToday)()));
    }

    get to() {
      return (0, _dateFns.addDays)((0, _dateFns.endOfToday)(), this.args.model.value.to);
    }

    set to(value) {
      if (value === null) {
        return;
      }

      Ember.set(this.args.model.value, 'to', (0, _dateFns.differenceInDays)(value, (0, _dateFns.endOfToday)()));
    }

    commitChanges() {
      let {
        from,
        to
      } = this.args.model.value;

      if (this.fromValue === from && this.toValue === to) {
        return;
      }

      this.fromValue = from;
      this.toValue = to;
      this.args.updateFilters();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "from", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "from"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "to", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "to"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "commitChanges", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "commitChanges"), _class.prototype)), _class));
  _exports.default = DateRangeFilterComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DateRangeFilterComponent);
});