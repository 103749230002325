define("x-page/components/select-dropdown-state", ["exports", "ember-select/components/select-dropdown"], function (_exports, _selectDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selectDropdown.default.extend({
    setVisibility(list, token) {
      list.filter(el => el.id.toLowerCase().startsWith(token) || el.name.toLowerCase().startsWith(token)).forEach(el => el.set('isVisible', true));
    }

  });

  _exports.default = _default;
});