define("x-video/components/x-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // AWS SDK tries to use `global` without setting it
  window.global = window;
  const CHIMEJS_PATH = '//cdn.healthcaresource.com/assets/chimejs/2.24.0';
  const SVG = '<svg class="svg-inline--fa fa-user fa-w-14" focusable="false" role="img" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-icon="user" data-prefix="fas" style="color: #CBD5E0; padding-top: .25rem;"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>'; // this component is not stand alone! It should be extended and the following should be added:
  // `ajax` should be injected
  // a UI should be provided
  // ajoId, name, optional userId, and optional pwd should be passed to the component

  var _default = Ember.Component.extend({
    attendees: null,
    audioVideo: null,
    isBrowserSupported: false,
    isContentShareSupported: false,
    isConnected: false,
    isConnectedElsewhere: false,
    isJoining: false,
    isLeaving: false,
    isLoadingAudio: false,
    isLoadingContent: false,
    isLoadingVideo: false,
    pwdQuery: '',
    sendAudio: true,
    sendContent: false,
    sendVideo: true,
    userSvgUrl: null,
    videoTiles: null,
    ajoId: null,
    name: null,
    pwd: null,
    allStreams: Ember.computed('attendees.[]', 'videoTiles.[]', function () {
      return this.attendees.map(attendee => ({
        attendee,
        video: this.videoTiles.find(x => x.attendeeId === attendee.attendeeId)
      }));
    }),
    applicantStream: Ember.computed('allStreams.[]', function () {
      return this.allStreams.find(x => !x.attendee.userId);
    }),
    contentStream: Ember.computed('videoTiles.[]', function () {
      return this.videoTiles.find(x => x.isContent);
    }),
    otherStreams: Ember.computed('allStreams.[]', function () {
      return this.allStreams.filter(x => x.attendee.userId && x.attendee.userId !== this.userId && !x.isContent);
    }),
    userStream: Ember.computed('allStreams.[]', function () {
      return this.userId && this.allStreams.find(x => x.attendee.userId === this.userId && !x.isContent);
    }),

    async init() {
      this._super(...arguments);

      let isBrowserSupported = false;
      let isContentShareSupported = false;
      let userSvgUrl = null;

      try {
        // Module export weirdness due to rollup mangling
        // Might could to it better once rollup fixes their commonjs issues
        let chime = await this.chimeJs();
        let {
          DefaultBrowserBehavior
        } = chime.__moduleExports;
        let browser = new DefaultBrowserBehavior(true);
        isBrowserSupported = browser.isSupported(); // chime doesn't have a convenience method for this check yet
        // https://github.com/aws/amazon-chime-sdk-js/issues/301
        // and a bug in older mobile browsers prevents a simple
        // javascript check for `getDisplayMedia`
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1624181
        // Safari only supports with experimental flag

        isContentShareSupported = navigator.mediaDevices.getDisplayMedia && !browser.isAndroid() && (browser.isChrome() || browser.isEdge() || browser.isFirefox());
        let blob = new Blob([SVG], {
          type: 'image/svg+xml'
        });
        userSvgUrl = URL.createObjectURL(blob);
      } finally {
        this.setProperties({
          attendees: Ember.A(),
          isBrowserSupported,
          isContentShareSupported,
          pwdQuery: this.pwd ? "?pwd=".concat(this.pwd) : '',
          userSvgUrl,
          videoTiles: Ember.A()
        });
      }
    },

    async willDestroyElement() {
      if (this.audioVideo) {
        if (this.sendContent) {
          await this.audioVideo.stopContentShare();
        }

        this.audioVideo.stop();
      }

      return this.ajax.delete("videos/".concat(this.ajoId).concat(this.pwdQuery));
    },

    actions: {
      connectVideoStream(tileId, element) {
        this.audioVideo.bindVideoElement(tileId, element);
      },

      async joinMeeting() {
        this.set('isJoining', true);

        try {
          let video = await this.ajax.get("videos/".concat(this.ajoId).concat(this.pwdQuery));

          if (video.isMaxAttendees) {
            return this.showError('The maximum number of attendees have already joined this meeting.');
          }

          if (!video.attendee) {
            return this.set('isConnectedElsewhere', true);
          } // would get picked up by `attendeePresenceObserver`, but adding
          // here provides a better UX while connecting


          this.attendees.pushObject({
            id: this.userId || this.ajoId,
            name: this.name,
            userId: this.userId,
            attendeeId: video.attendee.AttendeeId
          }); // Module export weirdness due to rollup mangling
          // Might could to it better once rollup fixes their commonjs issues

          let chime = await this.chimeJs();
          let {
            ConsoleLogger,
            DefaultDeviceController,
            MeetingSessionConfiguration,
            DefaultMeetingSession,
            LogLevel
          } = chime.__moduleExports;
          let logger = new ConsoleLogger('AWS::ChimeSdk', LogLevel.ERROR);
          let controller = new DefaultDeviceController(logger);
          let config = new MeetingSessionConfiguration(video.meeting, video.attendee);
          let session = new DefaultMeetingSession(config, logger, controller);
          let av = session.audioVideo;
          this.set('audioVideo', av);
          let [audioInput] = await av.listAudioInputDevices();

          if (audioInput) {
            await av.chooseAudioInputDevice(audioInput.deviceId);
          }

          let {
            DefaultBrowserBehavior
          } = chime.__moduleExports;
          let browser = new DefaultBrowserBehavior(true);

          if (browser.supportsSetSinkId()) {
            let [audioOutput] = await av.listAudioOutputDevices(); // output must always be set, even if 'null'; 'null' uses default output

            await av.chooseAudioOutputDevice(audioOutput ? audioOutput.deviceId : null);
          }

          let audioElement = document.getElementById('video-audio');
          await av.bindAudioElement(audioElement);
          av.addObserver(this); // defines the class that contains `videoTileDidUpdate` and `videoTileWasRemoved`

          av.realtimeSubscribeToAttendeeIdPresence(this.attendeePresenceObserver.bind(this));
          av.start(); // must be called after binding audio and subscribing to attendeePresence

          let [videoInput] = await av.listVideoInputDevices();

          if (videoInput) {
            await av.chooseVideoInputDevice(videoInput.deviceId);
          }

          av.startLocalVideoTile();
          this.set('isConnected', true);
        } catch (err) {
          console.log(err);
          this.showError('An error occurred attempting to connect to the video session');
          this.send('leaveMeeting');
        } finally {
          this.set('isJoining', false);
        }
      },

      disconnectVideoStream(tileId) {
        // not strictly required, but seems semantically correct
        this.audioVideo.unbindVideoElement(tileId);
      },

      async leaveMeeting() {
        this.set('isLeaving', true);

        try {
          if (this.audioVideo) {
            if (this.sendContent) {
              await this.audioVideo.stopContentShare();
            }

            this.audioVideo.stop();
          }

          await this.ajax.delete("videos/".concat(this.ajoId).concat(this.pwdQuery));
        } finally {
          this.setProperties({
            attendees: Ember.A(),
            audioVideo: null,
            isConnected: false,
            isConnectedElsewhere: false,
            isLeaving: false,
            sendAudio: true,
            sendContent: false,
            sendVideo: true,
            video: null,
            videoTiles: Ember.A()
          });
        }
      },

      async leaveThenJoin() {
        this.set('isLeaving', true);

        try {
          if (this.audioVideo) {
            if (this.sendContent) {
              await this.audioVideo.stopContentShare();
            }

            this.audioVideo.stop();
          }

          await this.ajax.delete("videos/".concat(this.ajoId).concat(this.pwdQuery));
          this.send('joinMeeting');
        } catch {
          this.showError('An error occurred attempting to disconnect other video sessions');
        } finally {
          this.setProperties({
            isConnectedElsewhere: false,
            isLeaving: false,
            sendContent: false
          });
        }
      },

      async toggleAudio() {
        this.set('isLoadingAudio', true);

        if (this.toggleProperty('sendAudio')) {
          await this.audioVideo.realtimeUnmuteLocalAudio();
        } else {
          await this.audioVideo.realtimeMuteLocalAudio();
        }

        this.set('isLoadingAudio', false);
      },

      async toggleVideo() {
        this.set('isLoadingVideo', true);

        if (this.toggleProperty('sendVideo')) {
          let [videoInput] = await this.audioVideo.listVideoInputDevices();

          if (videoInput) {
            await this.audioVideo.chooseVideoInputDevice(videoInput.deviceId);
          }

          await this.audioVideo.startLocalVideoTile();
        } else {
          await this.audioVideo.stopLocalVideoTile();
        }

        this.set('isLoadingVideo', false);
      },

      async toggleContentShare() {
        this.set('isLoadingContent', true);

        try {
          if (this.toggleProperty('sendContent')) {
            await this.audioVideo.startContentShareFromScreenCapture();
          } else {
            await this.audioVideo.stopContentShare();
          }
        } catch {
          // selecting 'cancel' in browser propmpt throws a permission denied exception
          this.toggleProperty('sendContent');
        } finally {
          this.set('isLoadingContent', false);
        }
      }

    },

    // dynamically load Chime JS so as to not add an unnecessary dependency
    // that bloats our app footprint
    chimeJs() {
      return new Ember.RSVP.Promise(resolve => {
        if (window.ChimeSDK) {
          return resolve(window.ChimeSDK);
        }

        return resolve(this.loadChimeJs());
      });
    },

    loadChimeJs() {
      return new Ember.RSVP.Promise(resolve => {
        let js = document.createElement('script');
        js.type = 'text/javascript';
        js.src = "".concat(CHIMEJS_PATH, "/amazon-chime-sdk.min.js");

        js.onload = () => resolve(window.ChimeSDK);

        document.getElementsByTagName('head').item(0).appendChild(js);
      });
    },

    showError(msg) {
      return this.notify ? this.notify.error(msg) : alert(msg);
    },

    // to preserve privacy, unhandled exceptions in the callbacks below are treated as fatal.
    // triggers when a participant joins or leaves a meeting
    // does not provide information about their video stream,
    // but does indicate we're getting their audio (assuming not muted)
    async attendeePresenceObserver(attendeeId, present, hhsId) {
      if (attendeeId.endsWith('#content')) {
        return;
      }

      if (present && !this.attendees.some(x => x.attendeeId === attendeeId)) {
        try {
          let attendee = await this.ajax.get("videos/".concat(this.ajoId, "/attendees/").concat(hhsId).concat(this.pwdQuery));
          attendee.attendeeId = attendeeId;
          this.attendees.pushObject(attendee);
        } catch {
          this.showError('A new participant has joined but we were unable to find their information');
        }
      } else if (!present) {
        let attendee = this.attendees.find(x => x.attendeeId === attendeeId);

        if (attendee) {
          this.attendees.removeObject(attendee);
        }
      }
    },

    // triggers when video is shared or a change is made to the incoming video
    // does not trigger when video stops being shared
    async videoTileDidUpdate(tile) {
      if (!tile.boundAttendeeId) {
        return;
      }

      let existing = this.videoTiles.find(x => x.attendeeId === tile.boundAttendeeId);

      if (existing && existing.tileId === tile.tileId) {
        // already tracking this tile
        return;
      } // edge case: `videoTileWasRemoved` doesn't trigger correctly


      if (existing) {
        this.audioVideo.unbindVideoElement(existing.tileId);
        this.videoTiles.removeObject(existing);
      }

      this.videoTiles.pushObject({
        tileId: tile.tileId,
        attendeeId: tile.boundAttendeeId,
        isContent: tile.isContent
      });
    },

    // triggers when video is no longer being shared (including when they leave the meeting)
    videoTileWasRemoved(tileId) {
      let tile = this.videoTiles.find(x => x.tileId === tileId);

      if (tile) {
        this.videoTiles.removeObject(tile); // content sharing can be ended by the browser

        if (this.sendContent && !this.contentStream) {
          this.set('sendContent', false);
        }
      }
    }

  });

  _exports.default = _default;
});