define("ember-atoms/components/text-area", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="hcs-text" ...attributes>
    <label class="hcs-label">
      <span class="block">
        {{yield}}
      </span>
  
      <textarea
        autofocus={{@autofocus}}
        class="hcs-text-input"
        disabled={{@isDisabled}}
        maxlength={{@maxLength}}
        rows={{@rows}}
        placeholder={{@placeholder}}
        readonly={{@isReadonly}}
        {{on "input" this.onInput}}
        {{on "keydown" this.onKeyDown}}
        {{did-insert this.didInsert}}
      >
        {{~@value~}}
      </textarea>
    </label>
  </div>
  
  */
  {"id":"DoZ3FNAE","block":"{\"symbols\":[\"&attrs\",\"&default\",\"@autofocus\",\"@isDisabled\",\"@maxLength\",\"@rows\",\"@placeholder\",\"@isReadonly\",\"@value\"],\"statements\":[[11,\"div\"],[24,0,\"hcs-text\"],[17,1],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"hcs-label\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"block\"],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"textarea\"],[16,\"autofocus\",[32,3]],[24,0,\"hcs-text-input\"],[16,\"disabled\",[32,4]],[16,\"maxlength\",[32,5]],[16,\"rows\",[32,6]],[16,\"placeholder\",[32,7]],[16,\"readonly\",[32,8]],[4,[38,0],[\"input\",[32,0,[\"onInput\"]]],null],[4,[38,0],[\"keydown\",[32,0,[\"onKeyDown\"]]],null],[4,[38,1],[[32,0,[\"didInsert\"]]],null],[12],[1,[32,9]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\"]}","meta":{"moduleName":"ember-atoms/components/text-area.hbs"}});

  const Enter = 13;

  /**
    `TextArea` enables the user to input when the expected content is more than one sentence.
  
    ```js
      <TextArea
        @autofocus={{true}}
        @isDisabled={{this.isDisabled}}
        @maxRows={{5}}
        @placeholder="Explain"
        @rows={{2}}
        @value={{this.explanation}}
        @onInput={{this.onInput}}
        @onSubmit={{this.onSubmit}}
      >
        Explain
      </TextArea>
    ```
  
    @class TextArea
   */
  let TextArea = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class TextArea extends _component.default {
    constructor(...args) {
      var _this$args$value$leng, _this$args$value;

      super(...args);

      _defineProperty(this, "previousValueLength", (_this$args$value$leng = (_this$args$value = this.args.value) === null || _this$args$value === void 0 ? void 0 : _this$args$value.length) !== null && _this$args$value$leng !== void 0 ? _this$args$value$leng : 0);
    }

    didInsert(element) {
      this.resize(element);

      if (this.args.autofocus) {
        element.focus();
      }
    }

    onInput(event) {
      let element = event.target;
      this.resize(element);
      this.previousValueLength = element.value.length;
      this.args.onInput(element.value, event);
    }

    onKeyDown(event) {
      // Handle `CMD + Enter` and `CTRL + Enter`
      if (this.args.onSubmit && event.keyCode === Enter && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        this.args.onSubmit(event);
      }
    }

    resize(element) {
      let {
        maxRows,
        rows
      } = this.args;

      if (this.previousValueLength > element.value.length) {
        element.rows = rows;
        this.refreshIe(element);
      }

      while (element.rows < maxRows && element.clientHeight < element.scrollHeight) {
        element.rows++;
        this.refreshIe(element);
      }
    }

    refreshIe(element) {
      // @ts-ignore IE: Force refresh
      if (document.documentMode) {
        element.blur();
        element.focus();
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInput", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype)), _class));
  _exports.default = TextArea;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TextArea);
});