define("ember-atoms/components/button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    class="hcs-btn {{if @isLoading "loading"}}"
    disabled={{this.isDisabled}}
    type="button"
    {{on "click" @onClick}}
    ...attributes
  >
    {{#if @isLoading}}
      <span class="absolute left-0">
        <FaIcon @icon="spinner" @spin={{true}} />
      </span>
    {{/if}}
  
    <span>
      {{yield}}
    </span>
  </button>
  
  */
  {"id":"xOMfbfb8","block":"{\"symbols\":[\"@isLoading\",\"&attrs\",\"@onClick\",\"&default\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"hcs-btn \",[30,[36,0],[[32,1],\"loading\"],null]]]],[16,\"disabled\",[32,0,[\"isDisabled\"]]],[17,2],[24,4,\"button\"],[4,[38,1],[\"click\",[32,3]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"absolute left-0\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@spin\"],[\"spinner\",true]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}","meta":{"moduleName":"ember-atoms/components/button.hbs"}});

  /**
    `Button` is used to initialize an action.
  
    ```js
      <Button
        @isDisabled={{this.isDisabled}}
        @isLoading={{this.isLoading}}
        @onClick={{this.clickSomething}}
      >
        Click Me!
      </Button>
    ```
  
    @class Button
   */
  class Button extends _component.default {
    get isDisabled() {
      return this.args.isDisabled || this.args.isLoading;
    }

  }

  _exports.default = Button;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Button);
});