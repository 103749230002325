define("x-page/utils/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.boxValue = boxValue;
  _exports.extractModel = extractModel;
  _exports.validateModel = validateModel;
  _exports.ElementType = void 0;
  const ElementType = {
    Check: 6,
    Combo: 4,
    Date: 25,
    FileManager: 30,
    FileUpload: 29,
    Input: 1,
    Label: 0,
    Radio: 2,
    TextArea: 5,
    Title: 3
  };
  _exports.ElementType = ElementType;
  const REQUIRED = 'Please complete this information.';
  const LOCALE = 'en-US';

  function fieldHasChanged(element, store) {
    var _value;

    let {
      field,
      value
    } = element;
    let snapshot = Ember.get(store, field); // `null` !== `undefined` !== `''`

    if (Ember.isBlank(snapshot) && Ember.isBlank(value)) {
      return false;
    } // Ensure dates have the same format


    if (((_value = value) === null || _value === void 0 ? void 0 : _value.toISOString) && snapshot) {
      snapshot = new Date(snapshot).toISOString();
      value = value.toISOString();
    }

    return snapshot !== value;
  }

  function boxValue(element) {
    var _element$value;

    // Strip time out of dates
    if ((_element$value = element.value) === null || _element$value === void 0 ? void 0 : _element$value.toISOString) {
      return "".concat(element.value.toLocaleDateString(LOCALE), " 00:00");
    }

    return element.value;
  }

  function extractModel(elements, snapshot = {}, blacklist = []) {
    return elements.filter(x => x.isVisible).filter((x, i, collection) => collection.toArray().findIndex(y => y.field === x.field) === i).filter(x => Ember.isPresent(x.field)).filter(x => fieldHasChanged(x, snapshot)).filter(x => !blacklist.includes(x.field)).map(x => ({
      field: x.field,
      value: boxValue(x)
    }));
  }

  function validateModel(elements, snapshot = {}) {
    // Clear previous element errors
    elements.setEach('error', null);
    elements.filter(x => x.isVisible).filter(x => x.required).filter(x => x.field).forEach(x => x.set('error', Ember.isBlank(x.value) ? REQUIRED : null));
    elements.filter(x => x.isVisible).filter(x => x.inputMask && x.inputMask !== 'decimal' && x.inputMask !== 'number').filter(x => {
      var _x$value;

      return ((_x$value = x.value) === null || _x$value === void 0 ? void 0 : _x$value.length) < x.inputMask.length;
    }).forEach(x => x.set('error', REQUIRED)); // Checkboxes have to be validated always, even if not required

    elements.filter(x => x.isVisible).filter(x => x.field).filter(x => !x.isMasked || fieldHasChanged(x, snapshot)).filter(x => Ember.isPresent(x.value) || x.type === ElementType.Check).filter(x => Ember.isPresent(x.validExp) && Ember.isBlank(x.error)).forEach(x => {
      let validator = new Function('s', x.validExp);
      x.set('error', validator(x.value) ? null : x.validMsg);
    });
  }
});