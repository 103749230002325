define("v-avatar/utils/avatar-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateColor = generateColor;
  const COLORS = ['#00a1de', '#eaab00', '#d2492a'];

  function generateColor(name) {
    let hash = name.split('').reduce((acc, val) => acc + val.charCodeAt(), 0);
    hash %= COLORS.length;
    return COLORS[hash];
  }
});